import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Box,
  Text,
  useDisclosure,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { onUpdateCashOnDelivery } from "../../../api/orders";
export function CashOnDeliveryAction({ row, setOpenLoader }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { register, handleSubmit, formState, setValue, watch, reset } = useForm(
    {
      defaultValues: {
        amount: "",
      },
    }
  );

  useEffect(() => {
    register("amount", {
      required: "amount is required",
      validate: (value) => (+value < 9999 ? true : "Invalid amount"),
    });
  }, [isOpen]);

  const { errors, isDirty } = formState;
  const form = watch();

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const handelSubmit = async (data) => {
    setOpenLoader(true);
    const body = { order_number: row.orderNumber, ...data };
    const res = await onUpdateCashOnDelivery(body, toast);
    if (res) {
      toast({
        status: "success",
        title: "",
        description: "Updated successfully",
      });
      onCloseModal();
    }
    setOpenLoader(false);
  };

  const onError = (errors) => console.log(errors);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>Cash On Delivery</Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cash On Delivery</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired isInvalid={!!errors.amount}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Amount
              </FormLabel>
              <InputGroup>
                <Input
                  placeholder="Enter amount"
                  type="number"
                  value={form["amount"]}
                  onChange={(e) => setValue("amount", e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.amount?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handelSubmit, onError)}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
