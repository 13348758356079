import {
  Icon,
  Input,
  Center,
  Text,
  Card,
  Box,
  FormControl,
  useToast,
  FormLabel,
  Select,
  Button,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tfoot,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import {
  validateImportCenterFile,
  getImportCenterConfig,
} from "../../api/import-center";
import { SSE } from "sse.js";

const UploadIcon = (props) => (
  <Icon width="28px" height="36px" viewBox="0 0 28 36" fill="none" {...props}>
    <path
      d="M23.1 14H25.7C26.0448 14 26.3754 14.1446 26.6192 14.402C26.863 14.6594 27 15.0085 27 15.3725V33.6275C27 33.9915 26.863 34.3406 26.6192 34.598C26.3754 34.8554 26.0448 35 25.7 35H2.3C1.95522 35 1.62456 34.8554 1.38076 34.598C1.13696 34.3406 1 33.9915 1 33.6275V15.3725C1 15.0085 1.13696 14.6594 1.38076 14.402C1.62456 14.1446 1.95522 14 2.3 14H4.9"
      stroke="#7F7D80"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 25.6666V4.66663"
      stroke="#7F7D80"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3335 9.33329L14.0002 4.66663L9.66683 9.33329"
      stroke="#7F7D80"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
function ImportCenter() {
  const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;
  const [columns, setColumns] = useState([
    { name: "order_number", label: "Order Number" },
    { name: "reason", label: "Reason" },
  ]);
  const EventSource = SSE;
  const toast = useToast();
  const inputRef = useRef(null);
  const [acceptedFiles, setAcceptedFiles] = useState();
  const [file, setFile] = useState();
  const [sampleFileUrl, setSampleFileUrl] = useState();
  const [selectedConfig, setSelectedConfig] = useState();
  const [importTypes, setImportTypes] = useState();
  const [rejectedRows, setRejectedRows] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  const tempRejected = [];
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const getToken = () => {
    return localStorage?.getItem("tokenKey");
  };

  const onClickValidate = () => {
    if (file) {
      const formData = new FormData();
      setIsValidateLoading(true);
      formData.append("file", file);
      validateImportCenterFile(selectedConfig.name, formData, toast).then(
        (res) => {
          setIsValidateLoading(false);
          setRejectedRows(res.data.body.errors);
          if (res.data.body.errors.length === 0) {
            setIsValidated(true);
            toast({
              status: "success",
              title: "File Validated",
              description: "Ready To Import",
            });
          }
          if (res.data.body.errors.length > 0) {
            setFile(null);
            toast({
              status: "error",
              title: "File removed",
              description: "Please Check the rejected rows and validate again",
            });
          }
        }
      );
    } else {
      toast({
        status: "error",
        title: "Error.",
        description: "You have to select file first",
      });
    }
  };

  const onClickImport = () => {
    setIsImportLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const eventSource = new EventSource(
      baseURL + `/import/${selectedConfig.name}`,
      {
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        payload: formData,
      }
    );
    eventSource.stream();
    eventSource.addEventListener("message", ({ data }) => {
      const res = JSON.parse(data);
      if (res.success === false) {
        tempRejected.push(res);
      }
    });
    eventSource.addEventListener("close", ({ data }) => {
      if (tempRejected.length > 0) {
        setRejectedRows(tempRejected);
        toast({
          status: "error",
          title: "Error.",
          description: "Please Check the Rejected Rows",
        });
      } else if (tempRejected.length === 0) {
        toast({
          status: "success",
          title: "Success",
          description: "Uploaded Successfully",
        });
      }
      setFile(null);
      setIsImportLoading(false);
      setIsValidated(false);
    });
  };

  const getConfigs = async () => {
    const res = await getImportCenterConfig();
    return res;
  };

  const onChangeImportType = (e) => {
    const curConfig = importTypes.find((type) => type.name === e.target.value);
    setSelectedConfig(curConfig);
    setSampleFileUrl(curConfig["file-url"]);
    setAcceptedFiles(curConfig["allowed-types"]);
    setFile(null);
    setRejectedRows([]);
  };

  useEffect(() => {
    getConfigs().then((res) => {
      setImportTypes(res);
    });
  }, []);
  return (
    <>
      <Card marginTop={"2rem"} padding={6} borderRadius={40} height={"52rem"}>
        <Card>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Text fontSize={"1rem"} fontWeight={700}>
                Bulk Import
              </Text>
            </Box>
          </Box>
        </Card>
        <Card w={"40%"} flexDir={"column"}>
          <FormControl pt={"3.5rem"} isRequired>
            <FormLabel fontSize={12} fontWeight={"semibold"}>
              Import Type
            </FormLabel>
            <Select
              onChange={(e) => {
                onChangeImportType(e);
              }}
              placeholder="Select Import Type"
            >
              {importTypes?.map((type, index) => {
                return (
                  <option key={index} value={type.name}>
                    {type.label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {!sampleFileUrl ? (
            <Box pt={4} color={"grey"} fontSize={13}>
              Download Sample
            </Box>
          ) : (
            <Box
              pt={4}
              onClick={() => {
                window.open(baseURL + "/" + sampleFileUrl);
              }}
              color={"blue"}
              fontSize={13}
              cursor={"pointer"}
            >
              Download Sample
            </Box>
          )}
          {selectedConfig && (
            <Box pt={6}>
              <div className="app">
                <div className="parent">
                  <div className="file-upload">
                    <UploadIcon />
                    {!file && <p>Maximum file size 5mb</p>}
                    {file && <p>{file?.name}</p>}
                    <input
                      type="file"
                      onChange={(e) => {
                        if (
                          acceptedFiles.includes(e.target.files[0]?.type) &&
                          e.target.files[0]?.size < 5000000
                        ) {
                          setFile(e.target.files[0]);
                        } else
                          toast({
                            status: "error",
                            title: "Error.",
                            description: "Invalid File Type",
                          });
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>

              <Box pt={6}>
                <Button
                  color={"white"}
                  colorScheme="blue"
                  size="sm"
                  isLoading={isValidateLoading}
                  onClick={onClickValidate}
                >
                  Validate
                </Button>

                <Button
                  isLoading={isImportLoading}
                  ml={6}
                  isDisabled={!isValidated}
                  onClick={onClickImport}
                  size="sm"
                  color={"white"}
                  colorScheme="blue"
                >
                  Upload
                </Button>
              </Box>
            </Box>
          )}
        </Card>
        <Box borderRadius={16} p={6} w={"65%"}>
          {rejectedRows.length > 0 && (
            <TableContainer overflowX={"hidden"} overflowY={"auto"}>
              <Box
                fontSize={12}
                fontWeight={"semibold"}
                display={"flex"}
                color={"red"}
              >
                Rejected Rows
              </Box>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    {columns.map((col, index) => (
                      <Th fontSize={12} fontWeight={"semibold"} key={index}>
                        {col.label}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {rejectedRows.map((record, index) => (
                    <Tr key={index}>
                      {columns.map((col, i) => {
                        return (
                          <Td fontSize={12} fontWeight={"semibold"} key={i}>
                            {record[col.name]}
                          </Td>
                        );
                      })}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Card>
    </>
  );
}

export default ImportCenter;
