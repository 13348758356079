import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "components/Sidebar/Sidebar.js";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import FincartBlueLogo from "../assets/icons/FincartBlueLogo";

export default function Dashboard(props) {
  const { ...rest } = props;

  const getRoutes = (routes) => {
    return routes.filter(route => route?.validation()).map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={<prop.component />} key={key} />
        );
      } else return null;
    });
  };

  document.documentElement.dir = "ltr";

  return (
    <Box display={"flex"} gap={"18rem"}>
      <Box
        h="30%"
        w="100%"
        position="fixed"
        background={"rgb(0 100 250)"}
        bgSize="cover"
      />
      <Sidebar
        routes={routes.filter(route => route?.validation())}
        logo={
          <Flex justify-content="flex-start">
            <FincartBlueLogo />
          </Flex>
        }
        {...rest}
      />
      <MainPanel w={"100%"}>
        <PanelContent>
          <PanelContainer>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/orders" />} />
            </Routes>
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </Box>
  );
}
