import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Input,
  useDisclosure,
  Box,
  Select,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { addEmployee } from "../../../api/admin";
export function AddEmployee() {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      email: "",
      role: "",
      phone: "",
    },
  });
  const { errors, isDirty } = formState;
  const form = watch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const onError = (errors) => console.log(errors);

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const handelSubmit = async (data) => {
    const res = await addEmployee({ ...data }, toast);
    if (res) {
      onCloseModal();
      toast({
        status: "success",
        title: "",
        description: "Added successfully",
      });
    }
  };
  useEffect(() => {
    register("username", {
      required: "user name is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid Name",
    });
    register("password", {
      required: "password is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid password",
    });
    register("email", {
      required: "email is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid email",
    });
    register("role", {
      required: "role is required",
    });
    register("phone", {
      required: "phone is required",
      validate: (value) =>
        value.length > 1 && value.length < 14 ? true : "Invalid phone",
    });
  }, [isOpen]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        justifyContent={"center"}
        onClick={onOpen}
      >
        <Tooltip label="Add Employee">
          <Button pl={4} variant="unstyled" color={"#A0AEC0"} onClick={onOpen}>
            <Flex fontSize={12} gap={2}>
              <AddIcon boxSize={3} />
              Add Employee
            </Flex>
          </Button>
        </Tooltip>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Employee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!!errors.username} isRequired>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Name
              </FormLabel>
              <Input
                placeholder="Enter user name"
                type="text"
                value={form["username"]}
                onChange={(e) => setValue("username", e.target.value)}
              />
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Password
              </FormLabel>
              <Input
                placeholder="Enter password"
                type="password"
                value={form["password"]}
                onChange={(e) => setValue("password", e.target.value)}
              />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Email
              </FormLabel>
              <Input
                placeholder="Enter email"
                type="email"
                value={form["email"]}
                onChange={(e) => setValue("email", e.target.value)}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phone} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Phone
              </FormLabel>
              <Input
                placeholder="Enter phone"
                type="phone"
                value={form["phone"]}
                onChange={(e) => setValue("phone", e.target.value)}
              />
              <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.role} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Role
              </FormLabel>
              <Select
                onChange={(e) => setValue("role", e.target.value)}
                value={form["role"]}
                placeholder="Select role"
              >
                <option value="admin">Admin</option>
                <option value="super">Super Admin</option>
              </Select>
              <FormErrorMessage>{errors?.role?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter display={"flex"} gap={6}>
            <Button
              size="sm"
              color={"white"}
              colorScheme="blue"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color={"white"}
              colorScheme="blue"
              isLoading={isLoading}
              onClick={handleSubmit(handelSubmit, onError)}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
