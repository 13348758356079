import { useState, useEffect } from "react";
import { useToast, Card } from "@chakra-ui/react";
import ListContainer from "components/Wrappers/ListContainer";
import tableActions from "variables/tableActions";
import { columns } from "variables/couriersData";
import { getCouriers } from "api/couriers";
import { AddCourier } from "../../components/actions/couriers/add-courier";
import { Role } from "variables/Roles";
const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;
function Couriers() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    filters: {},
  });
  const accessLvl = localStorage.getItem("role")
    ? localStorage.getItem("role")
    : "";
  const [couriersData, setCouriersData] = useState([]);
  const globalActions = [
    {
      name: "add-courier",
      component: () => <AddCourier fetchData={fetchData} filterQuery={filterQuery} />,
      validation: () => accessLvl === Role.financeManager,
    },
  ];

  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getCouriers(query, toast);
    if (res) {
      setCouriersData(
        res.couriers.map((courier) => {
          return { ...courier, logo: `${baseURL}/${courier.logo}` };
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(filterQuery);
  }, [filterQuery.page]);

  return (
    <>
      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <ListContainer
          title="Couriers"
          columns={columns}
          data={couriersData}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          isLoading={isLoading}
          tableActions={tableActions["Couriers"]}
          globalActions={globalActions}
        />
      </Card>
    </>
  );
}

export default Couriers;
