export const columns = [
  {
    type: "string",
    name: "name",
    label: "Invoice name",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date",
    name: "created_at",
    label: "Created at",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date",
    name: "start_date",
    label: "Start date",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date",
    name: "end_date",
    label: "End date",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "merchant_email",
    label: "Email",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "merchant_name",
    label: "Merchant name",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "status",
    label: "Invoice status",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "merchant_prefix",
    label: "Prefix",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "number",
    name: "total_invoice_amount",
    label: "Total amount",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "number",
    name: "number_of_orders",
    label: "Orders number",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "merchant_status",
    label: "Merchant status",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
