import { Button } from "@chakra-ui/react";
import React from "react";

export function ExportAction(props) {
  const { onclickExport } = props;

  return (
    <>
      <Button
        size="sm"
        color={"white"}
        colorScheme="blue"
        onClick={onclickExport}
      >
        Export
      </Button>
    </>
  );
}
