import { Text, Input, Flex } from "@chakra-ui/react";

const TextFilter = (props) => {
  const { column, adjustFilters, value } = props;
  return (
    <Flex w={"100%"} direction="column" gap={2}>
      <Text fontSize={"13px"} fontWeight={600} lineHeight={"20px"}>
        {column.label}
      </Text>
      <Input
        height={10}
        value={value ?? ""}
        onChange={(e) => adjustFilters(column, e.target.value)}
      />
    </Flex>
  );
};

export default TextFilter;
