import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Input,
  useDisclosure,
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "components/Loader/loader";

import { onUpdateBalance } from "../../../api/merchants";

export function UpdateBalance({ row, isDeduct, fetchData, filterQuery }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [openLoader, setOpenLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      balance: "",
    },
  });

  const { errors, isDirty } = formState;
  const form = watch();
  const onError = (errors) => console.log(errors);

  const handelSubmit = async (data) => {
    setOpenLoader(true);
    const body = {
      balance: isDeduct ? Number(data.balance) * -1 : Number(data.balance),
      id: row._id,
    };
    const res = await onUpdateBalance(body, isDeduct, toast);

    if (res) {
      toast({
        status: "success",
        title: "",
        description: "Updated successfully",
      });
    }
    setOpenLoader(false);
    onCloseModal();
  };

  const onCloseModal = () => {
    reset();
    onClose();
    fetchData(filterQuery);
  };

  useEffect(() => {
    register("balance", {
      required: "balance is required",
      validate: (value) =>
        value.length > 0 && value.length < 9999 ? true : "Invalid value",
    });
  }, [isOpen]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>
          {isDeduct === true ? "Deduct" : "Add"} Balance
        </Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isDeduct === true ? "Deduct" : "Add"} Balance
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Loader isLoading={openLoader} />

            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Balance
              </FormLabel>
              <Input
                placeholder="Enter Balance"
                type="number"
                value={form["balance"]}
                onChange={(e) => setValue("balance", e.target.value)}
              />
              <FormErrorMessage>{errors.name?.balance}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handelSubmit, onError)}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
