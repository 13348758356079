import React, { useEffect, useState } from "react";
import { Select } from "chakra-react-select";
import { Flex, Text } from "@chakra-ui/react";

const MultiDropDownFilter = (props) => {
  const { column, value, adjustFilters } = props;
  const [selectedValues, setSelectedValues] = useState(value || []);

  useEffect(() => {
    if (value) setSelectedValues(value);
    else setSelectedValues([]);
  }, [value]);

  const handleChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
    adjustFilters(column, selectedOptions);
  };

  return (
    <Flex direction={"column"} w={"100%"} gap={2}>
      <Text fontSize={"13px"} fontWeight={600} lineHeight={"20px"}>
        {column.label}
      </Text>
      <div w={"100%"}>
        <Select
          placeholder=""
          isMulti
          isDisabled={column.disabled}
          value={selectedValues}
          onChange={(selectedOptions) => handleChange(selectedOptions)}
          options={column.options.map((option) => {
            return { value: option.value, label: option.label };
          })}
        />
      </div>
    </Flex>
  );
};

export default MultiDropDownFilter;
