import { Flex, IconButton } from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

const Pagination = (props) => {
  const { filterQuery, totalCount, setFilterQuery } = props;
  const onClick = (direction) => {
    if (direction === "first") {
      setFilterQuery({ ...filterQuery, page: 1 });
    } else if (direction === "prev") {
      setFilterQuery({
        ...filterQuery,
        page: filterQuery.page - 1,
      });
    } else if (direction === "next") {
      setFilterQuery({
        ...filterQuery,
        page: filterQuery.page + 1,
      });
    }
  };

  const disableNextPage = () => {
    return totalCount - filterQuery.page * filterQuery.limit <= 0;
  };
  return (
    <Flex
      pt={{ base: "120px", md: "15px" }}
      paddingRight={"25px"}
      pb={"10px"}
      justifyContent={"end"}
      alignItems={"center"}
      gap={2}
    >
      <IconButton
        isDisabled={filterQuery.page === 1}
        onClick={() => {
          onClick("first");
        }}
      >
        <ArrowLeftIcon boxSize={4} />
      </IconButton>
      <IconButton
        isDisabled={filterQuery.page === 1}
        onClick={() => {
          onClick("prev");
        }}
      >
        <ChevronLeftIcon boxSize={6} />
      </IconButton>
      <IconButton
        isDisabled={disableNextPage()}
        onClick={() => {
          onClick("next");
        }}
      >
        <ChevronRightIcon boxSize={6} />
      </IconButton>
    </Flex>
  );
};

export default Pagination;
