import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import TablesTableRow from "components/Tables/TablesTableRow";
import TableSkeleton from "components/Tables/TableSkeleton";

const MainTable = (props) => {
  const { columns, data, tableActions, setOpenLoader, isLoading, fetchData, filterQuery } = props;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          {columns?.map((column, index) => {
            return (
              <Th
                position={column.stickyLeft ? "sticky" : ""}
                left={column.stickyLeft ? "0" : ""}
                right={column.stickyRight ? "0" : ""}
                bg={"white"}
                fontSize={"13px"}
                color={"rgb(127, 125, 128)"}
                fontStyle={"normal"}
                textAlign={"center"}
                key={index}
                w={16}
                background={"rgb(251, 251, 251)"}
              >
                {column.label}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      {isLoading ? (
        <TableSkeleton columnsCount={columns.length} />
      ) : (
        <Tbody>
          {data.map((row, index, arr) => {
            return (
              <TablesTableRow
                key={index}
                row={row}
                columns={columns}
                actions={tableActions}
                setOpenLoader={setOpenLoader}
                filterQuery={filterQuery}
                fetchData={fetchData}
              />
            );
          })}
        </Tbody>
      )}
    </Table>
  );
};

export default MainTable;
