import { get, getExport, post, put, stream } from "./index";

export const getPricingPlansData = async (query, toast) => {
  return post("/plan-pricing/listing", { ...query }, undefined, toast);
};


export const updatePricingPlanStatus = async (id, toast) => {
  return put("/plan-pricing/" + id + '/status', undefined, undefined, toast);
};

export const getPricingPlansDetails = async (id, query, toast) => {
  return post("/plan-pricing/" + id + '/details', query, undefined, toast);
};

export const getGovernorates = async (toast) => {
  return get('/lookups/governorates', undefined, undefined, toast);
}

export const getAreas = async (query, toast) => {
  return post('/lookups/areas', { ...query }, undefined, toast);
}

export const getCouriers = (query, toast) => {
  return post('/courier/listing', { ...query }, undefined, toast);
}

export const getDistricts = (query, toast) => {
  return post('/lookups/districts', { ...query }, undefined, toast);
}

export const getPricingPlanCodConfig = async (toast) => {
  return getExport('/plan-pricing/cod-config', undefined, undefined, toast);
}

export const getPricingPlanCourierConfig = async (toast) => {
  return getExport('/plan-pricing/import-config', undefined, undefined, toast);
  
}

export const getCountries = async (toast) => {
  return get('/lookups/countries', undefined, undefined, toast);
}

export const getCouriersByCountry = async (country, toast) => {
  return get(`/courier?country=${country}`, undefined, undefined, toast);
}

export const validateAddPricingPlan = (query, toast, headers) => {
  return post('/plan-pricing/valid', { ...query }, { ...headers }, toast);
}

export const addPricingPlan = (body, toast, headers) => {
  const formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  return stream(
    '/plan-pricing/import',
    toast,
    { method: 'POST', payload: formData }
  );
}

