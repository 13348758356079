import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Tooltip,
  Card,
  IconButton,
  useToast,
  Image,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Center,
} from "@chakra-ui/react";
import { ChevronDownIcon, RepeatIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import ListContainer from "components/Wrappers/ListContainer";
import { CashOnDeliveryAction } from "../../components/actions/orders/cash-on-delivery";
import { OrderNotes } from "../../components/actions/orders/order-notes";
import { CompleteOrder } from "../../components/actions/orders/complete-order";
import { UpdateWeight } from "../../components/actions/orders/update-weight";
import { UpdateStatus } from "../../components/actions/orders/update-status";
import { PrintLabel } from "../../components/actions/orders/print-label";
import { ForceSync } from "../../components/actions/orders/force-sync";
import { getOrderDetailsLogs, getOrderDetails } from "../../api/orders";
import { Loader } from "../../components/Loader/loader";
import { Role } from "variables/Roles";
export function OrderDetails() {
  const [isDetailsReloadLoading, setIsDetailsReloadLoading] = useState(false);
  const [isOrderLogsLoading, setIsOrderLogsLoading] = useState(false);
  const [receiverData, setReceiverData] = useState();
  const [senderData, setSenderData] = useState();
  const [packageData, setPackageData] = useState();
  const [orderLogs, setOrderLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const accessLvl = localStorage.getItem("role")
    ? localStorage.getItem("role")
    : "";

  const id = useParams().id;
  const prepareBigStrings = (string) => {
    return string?.substring(0, 13) + "....";
  };
  const toast = useToast();

  const getOrderLogsData = async (id) => {
    const res = await getOrderDetailsLogs(id, toast);
    return res;
  };

  const getOrderDetailsData = async (id) => {
    const res = await getOrderDetails(id, toast);
    return res;
  };

  const columns = [
    {
      type: "string",
      name: "title",
      label: "Action",
      style: { width: "100%" },
      show: true
    },
    {
      type: "date",
      name: "created_at",
      label: "Creation date",
      style: { width: "100%" },
      show: true
    },
    {
      type: "string",
      name: "desc",
      label: "Description",
      style: { width: "100%" },
      show: true
    },
    {
      type: "string",
      name: "actor",
      label: "Actor",
      style: { width: "100%" },
      show: true
    },
  ];

  const onClickLoadLogs = () => {
    setOpenLoader(true);
    setIsLoading(true);
    setIsOrderLogsLoading(true);
    getOrderLogsData(id, toast).then((res) => {
      setOrderLogs(res.logs);
      setIsOrderLogsLoading(false);
      setIsLoading(false);
      setOpenLoader(false);
    });
  };

  const onClickReloadData = () => {
    setOpenLoader(true);
    setIsDetailsReloadLoading(true);
    getOrderDetailsData(id, toast).then((res) => {
      setSenderData(res?.sender);
      setPackageData(res?.package);
      setReceiverData(res?.reciever);
      setOpenLoader(false);
      setIsDetailsReloadLoading(false);
    });
  };

  useEffect(() => {
    setOpenLoader(true);

    setIsLoading(true);
    getOrderLogsData(id, toast).then((res) => {
      setOrderLogs(res.logs);
      setIsLoading(false);
    });

    getOrderDetailsData(id, toast).then((res) => {
      setSenderData(res?.sender);
      setPackageData(res?.package);
      setReceiverData(res?.reciever);
      setOpenLoader(false);
    });
  }, []);

  return (
    <>
      <Loader isLoading={openLoader} />

      <Card
        marginTop={"2rem"}
        borderRadius={40}
        minHeight={"94.8vh"}
        minWidth={"100%"}
      >
        <>
          <Box display={"flex"} p={6} flexDirection={"column"} width={"100%"}>
            <Box display={"flex"} alignItems={"center"} flexDir={"row-reverse"}>
              <IconButton
                isLoading={isDetailsReloadLoading}
                cursor={"pointer"}
                onClick={onClickReloadData}
                background={"transparent"}
                size="xs"
                icon={<RepeatIcon boxSize={4} />}
              />
              {packageData?.billing_status?.merchant === "new" && (
                <Menu>
                  <MenuButton
                    background={"transparent"}
                    position={"sticky"}
                    right={0}
                    minW={"6rem"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Center
                      textTransform={"none"}
                      pos="relative"
                      bgColor="inherit"
                      w="100%"
                      h="100%"
                    >
                      <Text
                        fontWeight={"700"}
                        fontSize={"0.75rem"}
                        letterSpacing={"0.05em"}
                        color={"#7F7D80"}
                      >
                        Actions
                      </Text>
                    </Center>
                  </MenuButton>
                  <MenuList>
                    {packageData.billing_status?.merchant === "new" && (
                      <MenuItem
                        justifyContent={"center"}
                        h={"2rem"}
                        fontWeight={500}
                        w={"100%"}
                        padding={1}
                        fontSize={13}
                      >
                        <CashOnDeliveryAction
                          row={packageData}
                          setOpenLoader={setOpenLoader}
                        />
                      </MenuItem>
                    )}
                    {packageData.billing_status?.merchant === "new" && (
                      <MenuItem
                        justifyContent={"center"}
                        _focus={{ background: "" }}
                        _active={{ background: "" }}
                        _hover={{ background: "lightgrey" }}
                        h={"2rem"}
                        fontWeight={500}
                        w={"100%"}
                        padding={1}
                        fontSize={13}
                      >
                        <UpdateWeight
                          row={packageData}
                          setOpenLoader={setOpenLoader}
                        />
                      </MenuItem>
                    )}
                    {packageData.billing_status?.merchant === "new" && (
                      <MenuItem
                        justifyContent={"center"}
                        _focus={{ background: "" }}
                        _active={{ background: "" }}
                        _hover={{ background: "lightgrey" }}
                        h={"2rem"}
                        fontWeight={500}
                        w={"100%"}
                        padding={1}
                        fontSize={13}
                      >
                        <OrderNotes
                          row={packageData}
                          setOpenLoader={setOpenLoader}
                        />
                      </MenuItem>
                    )}
                    {packageData.billing_status?.merchant === "new" && (
                      <MenuItem
                        justifyContent={"center"}
                        _focus={{ background: "" }}
                        _active={{ background: "" }}
                        _hover={{ background: "lightgrey" }}
                        h={"2rem"}
                        fontWeight={500}
                        w={"100%"}
                        padding={1}
                        fontSize={13}
                      >
                        <UpdateStatus
                          row={packageData}
                          setOpenLoader={setOpenLoader}
                        />
                      </MenuItem>
                    )}
                    {packageData.billing_status?.merchant === "new" && (
                      <MenuItem
                        justifyContent={"center"}
                        _focus={{ background: "" }}
                        _active={{ background: "" }}
                        _hover={{ background: "lightgrey" }}
                        h={"2rem"}
                        fontWeight={500}
                        w={"100%"}
                        padding={1}
                        fontSize={13}
                      >
                        <PrintLabel
                          row={packageData}
                          setOpenLoader={setOpenLoader}
                        />
                      </MenuItem>
                    )}
                    {(packageData.status === "successful" ||
                      packageData.status === "unsuccessful") &&
                      packageData.billing_status?.merchant === "new" && (
                        <MenuItem
                          justifyContent={"center"}
                          _focus={{ background: "" }}
                          _active={{ background: "" }}
                          _hover={{ background: "lightgrey" }}
                          h={"2rem"}
                          fontWeight={500}
                          w={"100%"}
                          padding={1}
                          fontSize={13}
                        >
                          <CompleteOrder
                            row={packageData}
                            setOpenLoader={setOpenLoader}
                          />
                        </MenuItem>
                      )}
                    {packageData.billing_status?.merchant === "new" && (
                      <MenuItem
                        justifyContent={"center"}
                        _focus={{ background: "" }}
                        _active={{ background: "" }}
                        _hover={{ background: "lightgrey" }}
                        h={"2rem"}
                        fontWeight={500}
                        w={"100%"}
                        padding={1}
                        fontSize={13}
                      >
                        <ForceSync
                          row={packageData}
                          setOpenLoader={setOpenLoader}
                        />
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}
            </Box>
            <Box display={"flex"} gap={"4rem"} flexDir={"row"}>
              <Accordion width={"49%"} defaultIndex={[0]} allowMultiple>
                <Card borderColor={"#E2E8F0"} padding={12} borderRadius={18}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton padding={"0.5rem"}>
                        <Box
                          as="span"
                          flex="1"
                          fontSize={"13px"}
                          fontWeight={600}
                          textAlign="left"
                        >
                          Package Details
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize={"13px"}>
                      <Box display={"flex"} flexDir={"row"}>
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Courier:
                          </Text>
                        </Container>
                        <Container>
                          <Image
                            width="67px"
                            height="20px"
                            objectFit="contain"
                            src={
                              "https://api.fincart.io/admin/" +
                              packageData?.courier_logo
                            }
                            mb="1"
                          />
                        </Container>
                      </Box>
                      <Box display={"flex"} flexDir={"row"}>
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Brand Name:
                          </Text>
                        </Container>
                        <Container>
                          <Text>
                            {packageData?.brandName?.length > 15 ? (
                              <Tooltip
                                label={packageData?.brandName}
                                aria-label="A tooltip"
                              >
                                {prepareBigStrings(packageData?.brandName)}
                              </Tooltip>
                            ) : (
                              packageData?.brandName
                            )}
                          </Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Order Number:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.orderNumber}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Type:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.type}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Cash On Delivery:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.amount}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Packages Number:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.number_packages}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Allowed To Open:
                          </Text>
                        </Container>
                        <Container>
                          <Text>
                            {packageData?.open_package_allowed ? "Yes" : "No"}
                          </Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Description:
                          </Text>
                        </Container>
                        <Container>
                          {packageData?.description?.length > 15 ? (
                            <Tooltip
                              label={packageData?.description}
                              aria-label="A tooltip"
                            >
                              {prepareBigStrings(packageData?.description)}
                            </Tooltip>
                          ) : (
                            packageData?.description
                          )}
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Payment Method:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.payment_method}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Status:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.status}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            SubStatus:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.sub_status}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Support Notes:
                          </Text>
                        </Container>
                        <Container>
                          {packageData?.support_note?.length > 15 ? (
                            <Tooltip
                              label={packageData?.support_note}
                              aria-label="A tooltip"
                            >
                              {prepareBigStrings(packageData?.support_note)}
                            </Tooltip>
                          ) : (
                            packageData?.support_note
                          )}
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Reference Number:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.reference_number}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Tracking Number:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.tracking_number}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Creation Date:
                          </Text>
                        </Container>
                        <Container>
                          <Text>
                            {packageData?.creation_date
                              ? dayjs(packageData?.creation_date).format(
                                `ddd, D MMM${dayjs(packageData?.creation_date).isBefore(
                                  dayjs().startOf("year")
                                )
                                  ? "’" +
                                  dayjs(
                                    packageData?.creation_date
                                  ).format("YY")
                                  : ""
                                }`
                              )
                              : ""}
                          </Text>
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Processed On:
                          </Text>
                        </Container>
                        <Container>
                          <Text>
                            {packageData?.processed_on
                              ? dayjs(packageData?.processed_on).format(
                                `ddd, D MMM${dayjs(packageData?.processed_on).isBefore(
                                  dayjs().startOf("year")
                                )
                                  ? "’" +
                                  dayjs(packageData?.processed_on).format(
                                    "YY"
                                  )
                                  : ""
                                }`
                              )
                              : ""}
                          </Text>
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Shipping Fees:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.shipping_fees}</Text>
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            End Date:
                          </Text>
                        </Container>
                        <Container>
                          <Text>
                            {packageData?.end_date
                              ? dayjs(packageData?.end_date).format(
                                `ddd, D MMM${dayjs(packageData?.end_date).isBefore(
                                  dayjs().startOf("year")
                                )
                                  ? "’" +
                                  dayjs(packageData?.end_date).format(
                                    "YY"
                                  )
                                  : ""
                                }`
                              )
                              : ""}
                          </Text>
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Rejection Reason:
                          </Text>
                        </Container>
                        <Container>
                          {packageData?.rejection_reason?.length > 15 ? (
                            <Tooltip
                              label={packageData?.rejection_reason}
                              aria-label="A tooltip"
                            >
                              {prepareBigStrings(packageData?.rejection_reason)}
                            </Tooltip>
                          ) : (
                            packageData?.rejection_reason
                          )}
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Weight:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.weight}Kg</Text>
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Shipping Age:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.shipping_age}</Text>
                        </Container>
                      </Box>

                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Attempts:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{packageData?.attempts}</Text>
                        </Container>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Card>
              </Accordion>
              <Accordion width={"49%"} defaultIndex={[0, 1]} allowMultiple>
                <Card borderColor={"#E2E8F0"} padding={12} borderRadius={18}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton padding={"0.5rem"}>
                        <Box
                          as="span"
                          flex="1"
                          fontSize={"13px"}
                          fontWeight={600}
                          textAlign="left"
                        >
                          Consignee Details
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize={"13px"}>
                      <Box display={"flex"} flexDir={"row"}>
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Consignee Name:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{receiverData?.customer_name}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Consignee Phone:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{receiverData?.customer_phone}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Consignee City:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{receiverData?.customer_city}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Consignee Area:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{receiverData?.customer_area}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Consignee District:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{receiverData?.customer_district}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Consignee Address Line:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{receiverData?.customer_address_line}</Text>
                        </Container>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Card>
                <Card
                  borderColor={"#E2E8F0"}
                  marginTop={6}
                  padding={12}
                  borderRadius={18}
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton padding={"0.5rem"}>
                        <Box
                          as="span"
                          flex="1"
                          fontSize={"13px"}
                          fontWeight={600}
                          textAlign="left"
                        >
                          Sender Details
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize={"13px"}>
                      <Box display={"flex"} flexDir={"row"}>
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Sender Name:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{senderData?.sender_name}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Sender Phone:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{senderData?.sender_phone}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Sender City:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{senderData?.sender_city}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Sender Area:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{senderData?.sender_area}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Sender Address:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{senderData?.sender_address_line}</Text>
                        </Container>
                      </Box>
                      <Box
                        marginTop={"0.5rem"}
                        display={"flex"}
                        flexDir={"row"}
                      >
                        <Container>
                          <Text color={"gray"} fontWeight={"bold"}>
                            Sender District:
                          </Text>
                        </Container>
                        <Container>
                          <Text>{senderData?.sender_district}</Text>
                        </Container>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Card>
                <Card
                  borderColor={"#E2E8F0"}
                  marginTop={6}
                  padding={12}
                  borderRadius={18}
                >
                  {(accessLvl === Role.super ||
                    accessLvl === Role.superFinance) && (
                      <AccordionItem>
                        <h2>
                          <AccordionButton padding={"0.5rem"}>
                            <Box
                              as="span"
                              flex="1"
                              fontSize={"13px"}
                              fontWeight={600}
                              textAlign="left"
                            >
                              Billing Details
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} fontSize={"13px"}>
                          <Box display={"flex"} flexDir={"row"}>
                            <Container>
                              <Text color={"gray"} fontWeight={"bold"}>
                                Merchant Billing Status:
                              </Text>
                            </Container>
                            <Container>
                              <Text>{packageData?.billing_status?.merchant}</Text>
                            </Container>
                          </Box>
                          <Box
                            marginTop={"0.5rem"}
                            display={"flex"}
                            flexDir={"row"}
                          >
                            <Container>
                              <Text color={"gray"} fontWeight={"bold"}>
                                Courier Billing Status:
                              </Text>
                            </Container>
                            <Container>
                              <Text>
                                {packageData?.billing_status?.courier ?? "N/A"}
                              </Text>
                            </Container>
                          </Box>
                          <Box
                            marginTop={"0.5rem"}
                            display={"flex"}
                            flexDir={"row"}
                          >
                            <Container>
                              <Text color={"gray"} fontWeight={"bold"}>
                                Net Amount:
                              </Text>
                            </Container>
                            <Container>
                              <Text>{packageData?.merchant_take ?? "N/A"}</Text>
                            </Container>
                          </Box>
                          <Box
                            marginTop={"0.5rem"}
                            display={"flex"}
                            flexDir={"row"}
                          >
                            <Container>
                              <Text color={"gray"} fontWeight={"bold"}>
                                3PL Take:
                              </Text>
                            </Container>
                            <Container>
                              <Text>{packageData?.courier_take ?? "N/A"}</Text>
                            </Container>
                          </Box>
                          <Box
                            marginTop={"0.5rem"}
                            display={"flex"}
                            flexDir={"row"}
                          >
                            <Container>
                              <Text color={"gray"} fontWeight={"bold"}>
                                System Take:
                              </Text>
                            </Container>
                            <Container>
                              <Text>{packageData?.system_take ?? "N/A"}</Text>
                            </Container>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    )}
                </Card>
              </Accordion>
            </Box>
          </Box>

          <Box width={"100%"} padding={6}>
            <Box display={"flex"} flexDir={"row-reverse"}>
              <IconButton
                cursor={"pointer"}
                isLoading={isOrderLogsLoading}
                onClick={onClickLoadLogs}
                background={"transparent"}
                size="xs"
                aria-label="next-page"
                icon={<RepeatIcon boxSize={4} />}
              />
            </Box>
            <Accordion width={"100%"} defaultIndex={[0]} allowMultiple>
              <Card borderColor={"#E2E8F0"} padding={12} borderRadius={18}>
                <AccordionItem>
                  <h2>
                    <AccordionButton padding={"0.5rem"}>
                      <Box
                        as="span"
                        flex="1"
                        fontSize={"16px"}
                        fontWeight={500}
                        textAlign="left"
                      >
                        Logs
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <ListContainer
                      columns={columns}
                      data={orderLogs}
                      isLoading={isLoading}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Card>
            </Accordion>
          </Box>
        </>
      </Card>
    </>
  );
}
