import React, { useState } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import MainTable from "../Tables/MainTable";
import Filter from "components/Filters/Filter";
import Pagination from "./Pagination";
import { Loader } from "../Loader/loader.js";

const ListContainer = (props) => {
  const [openLoader, setOpenLoader] = useState(false);
  const searchableColumns = props.columns.filter((column) => column.searchable);

  return (
    <Flex direction="column">
      <Loader isLoading={openLoader} />
      <Flex
        paddingLeft={2}
        paddingBottom={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDir={"column"}>
          <Text fontSize="xl" fontWeight="bold">
            {props?.title}
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            {props?.totalCount}
          </Text>
        </Flex>
        <Box alignItems={"center"} marginRight={6} display={"flex"} gap={4}>
          {props.globalActions?.length > 0 &&
            props.globalActions
              ?.filter((action) => action.validation())
              ?.map((action) => <action.component />)}
          {props.columns.some((col) => col.searchable) && (
            <Filter
              columns={searchableColumns}
              filterQuery={props.filterQuery}
              setFilterQuery={props.setFilterQuery}
              fetchData={props.fetchData}
            />
          )}
        </Box>
      </Flex>
      {props.hasPagination && (
        <Pagination
          filterQuery={props.filterQuery}
          setFilterQuery={props.setFilterQuery}
          totalCount={props.totalCount}
        />
      )}
      <Box height={'30rem'} overflowX={{ sm: "scroll" }}>
        <MainTable
          tableActions={props.tableActions}
          setOpenLoader={setOpenLoader}
          columns={props.columns.filter(col => col.show)}
          data={props.data}
          isLoading={props.isLoading}
          filterQuery={props.filterQuery}
          fetchData={props.fetchData}
        />
      </Box>
    </Flex>
  );
};

export default ListContainer;
