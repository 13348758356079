import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Input,
  useDisclosure,
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { onCreateInvoice } from "../../../api/merchants";
import { Loader } from "components/Loader/loader";

export function CreateInvoice({ row }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [openLoader, setOpenLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      name: "",
      startDate: "",
      endDate: "",
    },
  });

  const { errors, isDirty } = formState;
  const form = watch();
  const onError = (errors) => console.log(errors);

  const handelSubmit = async (data) => {
    setOpenLoader(true);
    const startDateTimeStamp = new Date(data.startDate);
    const endDateTimeStamp = new Date(data.endDate);
    const body = {
      name: data.name,
      start_date: startDateTimeStamp.getTime(),
      end_date: endDateTimeStamp.getTime(),
      merchant_id: row._id,
    };
    const res = await onCreateInvoice(body, toast);

    if (res) {
      toast({
        status: "success",
        title: "",
        description: "Created successfully",
      });
    }
    onCloseModal();
    setOpenLoader(false);
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    register("name", {
      required: "name is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid name",
    });
    register("startDate", {
      required: "start Date is required",
      validate: (value) => (value ? true : "Invalid start date"),
    });
    register("endDate", {
      required: "end Date is required",
      validate: (value) => (value ? true : "Invalid end date"),
    });
  }, [isOpen]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>Create Invoice</Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Loader isLoading={openLoader} />

            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Name
              </FormLabel>
              <Input
                placeholder="Enter Name"
                type="text"
                value={form["name"]}
                onChange={(e) => setValue("name", e.target.value)}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.startDate} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Start Date
              </FormLabel>
              <Input
                placeholder="Enter Start Date"
                type="date"
                value={form["startDate"]}
                onChange={(e) => setValue("startDate", e.target.value)}
              />
              <FormErrorMessage>{errors.startDate?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.endDate} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                End Date
              </FormLabel>
              <Input
                placeholder="Enter End Date"
                type="date"
                value={form["endDate"]}
                onChange={(e) => setValue("endDate", e.target.value)}
              />
              <FormErrorMessage>{errors.endDate?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handelSubmit, onError)}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
