import { useState, useEffect } from "react";
import { useToast, Card } from "@chakra-ui/react";
import ListContainer from "components/Wrappers/ListContainer";
import tableActions from "variables/tableActions";
import { columns } from "variables/pricingPlansData";
import { Loader } from "components/Loader/loader";
import { getPricingPlansData } from "api/pricing-plans";
import { AddPricingPlan } from "components/actions/pricing-plans/add-pricing-plan";
function PricingPlans() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
    sortAttribute: "",
    sortDirection: "",
  });

  const [openLoader, setOpenLoader] = useState(false);

  const toast = useToast();

  const [pricingPlansData, setPricingPlansData] = useState([]);

  const globalActions = [
    {
      name: "add-pricing-plan",
      component: () => <AddPricingPlan fetchData={fetchData} filterQuery={filterQuery} />,
      validation: () => true,
    },

  ];

  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getPricingPlansData(query, toast);
    if (res) {
      setPricingPlansData(res.pricingPlans);
      setTotalCount(res.pricingPlansCount);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(filterQuery);
    columns.find((col) => col.type === "link")["onClickLink"] = (row) => {
      window.open("/admin/pricing-plans/" + row.id + "/details", "_blank");
    };
  }, [filterQuery.page]);

  return (
    <>
      <Loader isLoading={openLoader} />
      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <ListContainer
          title="Pricing Plans"
          columns={columns}
          data={pricingPlansData}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          totalCount={totalCount}
          tableActions={tableActions["PricingPlans"]}
          hasPagination={true}
          fetchData={fetchData}
          isLoading={isLoading}
          globalActions={globalActions}

        />
      </Card>
    </>
  );
}

export default PricingPlans;
