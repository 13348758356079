import { useToast, Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { SSE } from "sse.js";
const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;

export function ExportTrackMismatch({ row, type }) {
  const toast = useToast();
  const body = {
    id: row.id,
    type: type,
  };
  const EventSource = SSE;
  const [isInprogress, setIsInprogress] = useState(false);
  const prepareCSVData = (data) => {
    let csvData = data[0].split("\n")[0] + "\n";
    data.forEach((row) => {
      csvData += row.split("\n")[1] + "\n";
    });
    return csvData;
  };

  const exportCSVFile = (data) => {
    const csvData = prepareCSVData(data);
    const url = window.URL.createObjectURL(new Blob([csvData]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = "Tracking Mismatch.csv";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getToken = () => {
    return localStorage?.getItem("tokenKey");
  };

  const onClickExportMismatchFile = () => {
    if (isInprogress) {
      toast({
        status: "info",
        title: "Mismatch Is In Progress...",
        duration: 1000,
      });
      return;
    }
    setIsInprogress(true);
    toast({
      status: "info",
      title: "Downloading File",
      duration: 1000,
    });
    let csvData = [];
    const eventSource = new EventSource(
      baseURL + "/order/track-mismatch/export",
      {
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
        },
        method: "POST",
        payload: JSON.stringify(body),
      }
    );
    eventSource.stream();
    eventSource.addEventListener("message", ({ data }) => {
      const res = JSON.parse(data);
      if (res.success === true && res.tracking_result) {
        csvData.push(res.tracking_result);
      }
    });
    eventSource.addEventListener("close", ({ data }) => {
      if (csvData.length > 0) {
        exportCSVFile(csvData);
        toast({
          status: "success",
          title: "",
          description: "File Downloaded",
        });
      } else if (csvData.length === 0) {
        toast({
          status: "success",
          title: "",
          description: "No Tracking Mismtach Orders.",
        });
      }
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={() => {
          onClickExportMismatchFile();
        }}
      >
        <Text>Tracking Mismatch</Text>
      </Box>
    </>
  );
}
