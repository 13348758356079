import { useState, useEffect } from "react";
import ListContainer from "components/Wrappers/ListContainer";
import { columns } from "variables/leadsData";
import { getLeads } from "api/leads";
import { useToast, Card } from "@chakra-ui/react";
import tableActions from "variables/tableActions";
import { ExportAction } from "components/actions/global/export";
import { exportLeads } from "api/leads";

function Leads() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [filterQuery, setFilterQuery] = useState({
    filters: {},
  });

  const [leadsData, setLeadsData] = useState([]);
  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getLeads(query, toast);
    if (res) {
      setLeadsData(res.leads);
    }
    setIsLoading(false);
  };

  const onExport = () => exportLeads(filterQuery.filters, toast, console.log);

  const globalActions = [
    {
      name: "export",
      component: () => <ExportAction onclickExport={onExport} />,
      validation: () => true,
    },
  ];

  useEffect(() => {
    fetchData(filterQuery);
  }, [filterQuery.page]);

  return (
    <>
      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <ListContainer
          title="Leads"
          columns={columns}
          data={leadsData}
          filterQuery={filterQuery}
          fetchData={fetchData}
          setFilterQuery={setFilterQuery}
          tableActions={tableActions["Leads"]}
          globalActions={globalActions}
          hasPagination={false}
          isLoading={isLoading}
        />
      </Card>
    </>
  );
}

export default Leads;
