import {
  Box,
  useToast,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { onSettleInvoice } from "../../../api/invoices";
export function SettleInvoice({ row, setOpenLoader }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const settleInvoice = async () => {
    setOpenLoader(true);
    const settleInvoiceRes = await onSettleInvoice(row.id, toast);
    if (settleInvoiceRes) {
      toast({
        status: "success",
        title: "Invoice Settled.",
      });
      onClose();
    }
    setOpenLoader(false);
  };
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={() => {
          onOpen();
        }}
      >
        <Text>Settle Invoice</Text>
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Settle Invoice</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to settle this invoice
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button onClick={settleInvoice} colorScheme="red" ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
