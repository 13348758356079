import axios from "axios";
import { SSE } from "sse.js";
import globalApiWrapper from "wrappers/globalApiWrapper";

const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;

const token = () => {
  if (localStorage.getItem("tokenKey")) return localStorage.getItem("tokenKey");
};

export const get = globalApiWrapper(
  async (url, params = "", headers = { authorization: token() }, toast) => {
    return axios
      .get(baseURL + url + params, {
        headers,
      })
      .then((res) => {
        return res.data.body;
      });
  }
);

export const getExport = globalApiWrapper(
  async (url, params = "", headers = { authorization: token() }, toast) => {
    return axios
      .get(baseURL + url + params, {
        headers,
      })
      .then((res) => {
        return res;
      });
  }
);

export const post = globalApiWrapper(
  async (url, body, headers = { authorization: token() }, toast) => {
    return axios
      .post(baseURL + url, body, {
        headers,
      })
      .then((res) => {
        return res.data.body;
      });
  }
);

export const postExport = globalApiWrapper(
  async (url, body, headers = { authorization: token() }, toast) => {
    return axios
      .post(baseURL + url, body, {
        headers,
      })
      .then((res) => {
        return res;
      });
  }
);

export const put = globalApiWrapper(
  async (url, body, headers = { authorization: token() }, toast) => {
    return axios
      .put(baseURL + url, body, {
        headers,
      })
      .then((res) => {
        return res;
      });
  }
);

export const patch = globalApiWrapper(
  async (url, body, headers = { authorization: token() }, toast) => {
    return axios
      .patch(baseURL + url, body, {
        headers,
      })
      .then((res) => {
        return res;
      });
  }
);

export const postArrayBuffer = globalApiWrapper(
  async (url, body, headers = { authorization: token() }, toast) => {
    return axios
      .post(baseURL + url, body, { headers, responseType: "arraybuffer" })
      .then((res) => {
        return res;
      });
  }
);

export const getArrayBuffer = globalApiWrapper(
  async (
    url,
    params = "",
    headers = { authorization: token(), responseType: "arraybuffer" },
    toast
  ) => {
    return axios
      .get(baseURL + url + params, { headers, responseType: "arraybuffer" })
      .then((res) => {
        return res;
      });
  }
);

export const stream = (
  url,
  toast,
  { method = "GET", payload = undefined } = {}
) => {
  const events = new SSE(`${baseURL}${url.startsWith("/") ? url : "/" + url}`, {
    headers: {
      Authorization: localStorage.getItem("tokenKey"),
    },
    method,
    payload
  });
  events.addEventListener("error", (eventObject) => {
    toast({
      status: "error",
      title: "Error.",
      description: eventObject.data,
    });
  });
  return events;
};

export const download = globalApiWrapper(
  async (url, filename, type, headers = { authorization: token() }, toast) => {
    const response = await axios.get(baseURL + url, {
      headers,
    });
    const a = document.createElement("a");
    a.download = filename;
    a.href = `data:${type},${response.data}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return response;
  }
);
