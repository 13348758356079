import {
  useToast,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
  Switch,
  ModalFooter,
  Button,
  Icon,
  Text,
} from "@chakra-ui/react";
import "./styles.css";
import React, { useEffect, useRef, useState } from "react";
import { SSE } from "sse.js";
import { useForm } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import { CSVLink } from "react-csv";

import { getCourierConfig, validateNewCourier, getPricingPlanCodConfig } from "../../../api/couriers";

const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;
export function AddCourier({ row, fetchData, filterQuery }) {
  const toast = useToast();
  const EventSource = SSE;

  const UploadIcon = (props) => (
    <Icon width="24px" height="34px" viewBox="0 0 28 36" fill="none" {...props}>
      <path
        d="M23.1 14H25.7C26.0448 14 26.3754 14.1446 26.6192 14.402C26.863 14.6594 27 15.0085 27 15.3725V33.6275C27 33.9915 26.863 34.3406 26.6192 34.598C26.3754 34.8554 26.0448 35 25.7 35H2.3C1.95522 35 1.62456 34.8554 1.38076 34.598C1.13696 34.3406 1 33.9915 1 33.6275V15.3725C1 15.0085 1.13696 14.6594 1.38076 14.402C1.62456 14.1446 1.95522 14 2.3 14H4.9"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 25.6666V4.66663"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3335 9.33329L14.0002 4.66663L9.66683 9.33329"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      courierName: "",
      courierLogo: "",
      courierStatus: false,
    },
  });
  const { errors, isDirty } = formState;

  const getToken = () => {
    return localStorage?.getItem("tokenKey");
  };

  const csvLink = useRef();
  const finalRef = React.useRef(null);
  const [acceptedCODFile, setAcceptedCODFile] = useState();
  const [acceptedCourierFile, setAcceptedCourierFile] = useState();
  const [codFileSample, setCODFileSample] = useState();
  const [courierFileSample, setCourierFileSample] = useState();
  const [codFile, setCODFile] = useState();
  const [courierFile, setCourierFile] = useState();
  const [CODErrorFile, setCodErrorFile] = useState("");
  const [courierErrorFile, setCourierErrorFile] = useState("");
  const [isImportDisabled, setIsImportDisabled] = useState(true);
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);

  const onError = (errors) => console.log(errors);

  const handelSubmit = (data) => {
    if (!codFile) {
      toast({
        status: "error",
        title: "Invalid Cod File.",
        description: "Cod file is required",
      });
    }

    if (!courierFile) {
      toast({
        status: "error",
        title: "Invalid Courier File.",
        description: "Courier file is required",
      });
    }
    if (courierFile && codFile) {
      setIsValidateLoading(true);
      let body = {
        new: row ? false : true,
        courier_id: row ? row.id : undefined,
        name: data["courierName"],
        logo: data["courierLogo"],
        active: data["courierStatus"],
        "courier-cod": codFile,
        courier: courierFile,
      };

      validateNewCourier(
        body,
        { "Content-Type": "multipart/form-data", Authorization: getToken() },
        toast
      ).then((res) => {
        setIsValidateLoading(false);
        if (res?.errors && Object.keys(res?.errors).length > 0) {
          if (res.errors.CODFileErrors) {
            setCodErrorFile(res.errors.CODFileErrors);
            toast({
              status: "error",
              title: "Invalid Cod File.",
              description: "Please Check COD File",
            });
          }

          if (res.errors.courierErrors) {
            toast({
              status: "error",
              title: "Error.",
              description: res.errors.courierErrors,
            });
          }

          if (res.errors.courierFileErrors) {
            setCourierErrorFile(res.errors.courierFileErrors);
            toast({
              status: "error",
              title: "Error.",
              description: "Please Check Courier File",
            });
          }
        } else {
          setIsImportDisabled(false);
          toast({
            status: "success",
            title: "Success.",
            description: "Ready to import",
          });
        }
      });
    }
    setIsValidateLoading(false);
  };

  const onCloseModal = () => {
    reset();
    setCodErrorFile("");
    setCODFile();
    setCourierErrorFile("");
    setCourierFile();
    setIsValidateLoading(false);
    setIsImportLoading(false);
    onClose();
  };

  const addCourier = () => {
    setIsImportLoading(true);
    const formData = new FormData();
    if (!row) {
      formData.append("name", form["courierName"]);
      formData.append("logo", form["courierLogo"]);
      formData.append("active", form["courierStatus"]);
    }
    formData.append("courier-cod", codFile);
    formData.append("courier", courierFile);
    let url = "/courier/import";

    if (row) url = "/courier/" + row.id;
    const eventSource = new EventSource(baseURL + url, {
      headers: {
        Authorization: getToken(),
      },
      method: row ? "PUT" : "POST",
      payload: formData,
    });

    eventSource.stream();
    onCloseModal();
    toast({
      status: "info",
      title: "info.",
      description: "Please Wait",
    });

    eventSource.addEventListener("error", (eventObject) => {
      toast({
        status: "error",
        title: "Error.",
        description: eventObject.data,
      });
    });

    eventSource.addEventListener("message", (eventObject) => {
      if (!row) fetchData(filterQuery);
      toast({
        status: "success",
        title: "Success.",
        description: eventObject.data,
      });
      onCloseModal();
    });
  };

  const form = watch();

  const getConfig = async () => {
    const courierConfigRes = await getCourierConfig(toast);
    return courierConfigRes;
  };


  const getCourierConfigGet = () => {
    getCourierConfig(toast).then(res => {
      setAcceptedCourierFile(['text/csv'])
      setCourierFileSample(res);
    })
  }

  const getCodConfig = () => {
    getPricingPlanCodConfig(toast).then(res => {
      setAcceptedCODFile(['text/csv'])
      setCODFileSample(res);
    })
  }

  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  useEffect(() => {
    if (isOpen) {
      getCourierConfigGet();;

      getCodConfig();
    }
    register("courierName", {
      required: "Name is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid name",
    });
    register("courierLogo", {
      required: "Logo is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid logo",
    });
    register("courierStatus");
    setValue("courierStatus", false);
    if (row) {
      setValue("courierLogo", row.logo);
      setValue("courierStatus", row.active);
      setValue("courierName", row.name);
    }
  }, [isOpen]);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        {!row && (
          <Button onClick={onOpen}>
            <AddIcon boxSize={4} />
          </Button>
        )}
        {row && (
          <Box onClick={onOpen}>
            <Text>Edit Courier</Text>
          </Box>
        )}
      </Box>
      <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{!row ? "Add Courier" : `Edit ${row.name}`}</ModalHeader>
          {isValidateLoading || isImportLoading ? (
            ""
          ) : (
            <ModalCloseButton onClick={onCloseModal} />
          )}
          <ModalBody display={"flex"} flexDir={"row"} gap={16}>
            <Box w={"26rem"} display={"flex"} flexDir={"column"} gap={16}>
              <FormControl isRequired isInvalid={!!errors.courierName}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Name
                </FormLabel>
                <InputGroup>
                  <Input
                    isDisabled={row?.name}
                    placeholder="Enter Courier Name"
                    type="string"
                    value={form["courierName"]}
                    onChange={(e) => {
                      setIsImportDisabled(true);
                      setValue("courierName", e.target.value);
                    }}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors?.courierName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.courierLogo}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Logo
                </FormLabel>
                <InputGroup>
                  <Input
                    isDisabled={row?.logo}
                    placeholder="Enter Courier Logo"
                    type="string"
                    value={form["courierLogo"]}
                    onChange={(e) => {
                      setIsImportDisabled(true);
                      setValue("courierLogo", e.target.value);
                    }}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors?.courierLogo?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                onChange={(e) => setValue("courierStatus", e.target.checked)}
              >
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Status
                </FormLabel>
                <Switch
                  isDisabled={row?.active != null}
                  isChecked={row?.active}
                  size="lg"
                  colorScheme="blue"
                  value={form["courierStatus"]}
                  onChange={(e) => setValue("courierStatus", e.target.checked)}
                />
              </FormControl>
            </Box>

            <Box display={"flex"} flexDir={"column"}>
              <FormControl isRequired>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Cod File
                </FormLabel>
                <div className="app">
                  <div className="parent">
                    <div className="file-upload">
                      <UploadIcon />
                      {!codFile && <p>Maximum file size 5mb</p>}
                      {codFile && <p>{codFile.name}</p>}
                      <input
                        type="file"
                        onChange={(e) => {
                          setIsImportDisabled(true);
                          if (
                            acceptedCODFile.includes(e.target.files[0]?.type) &&
                            e.target.files[0]?.size < 5000000
                          ) {
                            setCODFile(e.target.files[0]);
                          } else
                            toast({
                              status: "error",
                              title: "Error.",
                              description: "Invalid File Type",
                            });
                        }}
                        onClick={(e) => {
                          setCodErrorFile("");
                          e.target.value = null;
                        }}
                      />
                    </div>
                    <Box
                      color={"blue"}
                      paddingTop={2}
                      fontSize={13}
                      cursor={"pointer"}
                      onClick={() => {
                        openFileInNewTab(codFileSample)
                      }}
                    >
                      Download Sample
                    </Box>
                  </div>
                </div>
                {CODErrorFile.length !== 0 && (
                  <Box display={"flex"} justifyContent={"center"}>
                    <Box>
                      <Box
                        color={"red"}
                        fontSize={13}
                        cursor={"pointer"}
                        onClick={async () => csvLink.current.link.click()}
                      >
                        Download Error
                      </Box>
                      <CSVLink
                        data={CODErrorFile}
                        filename="courier-cod-errors.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </Box>
                  </Box>
                )}
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Courier File
                </FormLabel>

                <div className="app">
                  <div className="parent">
                    <div className="file-upload">
                      <UploadIcon />
                      {!courierFile && <p>Maximum file size 5mb</p>}
                      {courierFile && <p>{courierFile.name}</p>}
                      <input
                        type="file"
                        onChange={(e) => {
                          setIsImportDisabled(true);
                          if (
                            acceptedCourierFile.includes(
                              e.target.files[0]?.type
                            ) &&
                            e.target.files[0]?.size < 5000000
                          ) {
                            setCourierFile(e.target.files[0]);
                          } else
                            toast({
                              status: "error",
                              title: "Error.",
                              description: "Invalid File Type",
                            });
                        }}
                        onClick={(e) => {
                          setCourierErrorFile("");
                          e.target.value = null;
                        }}
                      />
                    </div>
                    <Box
                      color={"blue"}
                      paddingTop={2}
                      fontSize={13}
                      cursor={"pointer"}
                      onClick={() => {
                        openFileInNewTab(courierFileSample)
                      }}
                    >
                      Download Sample
                    </Box>
                  </div>
                </div>
                {courierErrorFile.length !== 0 && (
                  <Box display={"flex"} justifyContent={"center"}>
                    <Box
                      color={"red"}
                      fontSize={13}
                      cursor={"pointer"}
                      onClick={async () => csvLink.current.link.click()}
                    >
                      Download Error
                    </Box>
                    <CSVLink
                      data={courierErrorFile}
                      filename="courier-errors.csv"
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                    />
                  </Box>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>

            <Button
              onClick={handleSubmit(handelSubmit, onError)}
              size="sm"
              color={"white"}
              colorScheme="blue"
              isDisabled={!isImportDisabled}
              isLoading={isValidateLoading}
            >
              Validate
            </Button>

            <Button
              isLoading={isImportLoading}
              isDisabled={isImportDisabled}
              onClick={addCourier}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
