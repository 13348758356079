import {
  ExternalLinkIcon,
  PlusSquareIcon,
  CalendarIcon,
  ChatIcon,
} from "@chakra-ui/icons";
import { PrintLabel } from "../components/actions/orders/print-label";
import { CashOnDeliveryAction } from "../components/actions/orders/cash-on-delivery";
import { OrderNotes } from "../components/actions/orders/order-notes";
import { UpdateWeight } from "../components/actions/orders/update-weight";
import { UpdateStatus } from "../components/actions/orders/update-status";
import PrintLabelIcon from "../assets/icons/PrintLabel";
import { ForceSync } from "components/actions/orders/force-sync";
import { CompleteOrder } from "components/actions/orders/complete-order";
import { ExportTrackMismatch } from "components/actions/couriers/tracking-mismatch";
import { CreateInvoice } from "components/actions/merchants/create-invoice";
import { UpdateBalance } from "components/actions/merchants/update-balance";
import { PrintInvoice } from "components/actions/invoices/print-invoices";
import { SettleInvoice } from "components/actions/invoices/settle-invoice";
import { AddCourier } from "components/actions/couriers/add-courier";
import { AddMerchant } from "components/actions/merchants/add-merchant";
import { AssignPlan } from "components/actions/merchants/assign-plan";
import { UpdateRecommendation } from "components/actions/merchants/update-recommendation";
import { Role } from "../variables/Roles";
import { UpdatePricingPlanStatus } from "components/actions/pricing-plans/update-status";
import { UpdateMerchantFees } from "components/actions/merchants/update-merchant-fees";
const accessLvl = localStorage.getItem("role")
  ? localStorage.getItem("role")
  : "";

const TableActions = {
  Orders: [
    {
      id: "print-label",
      icon: PrintLabelIcon,
      validation: (row) =>
        row.billing_status.merchant === "new" && row.status === "pending",
      component: (row) => (
        <PrintLabel row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "cash-on-delivery",
      icon: ExternalLinkIcon,
      validation: (row) => row.billing_status.merchant === "new",
      component: (row) => (
        <CashOnDeliveryAction row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "update-notes",
      icon: ChatIcon,
      validation: (row) => row.billing_status.merchant === "new",
      component: (row) => (
        <OrderNotes
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "update-weight",
      icon: CalendarIcon,
      validation: (row) => row.billing_status.merchant === "new",
      component: (row) => (
        <UpdateWeight row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "update-status",
      icon: PlusSquareIcon,
      validation: (row) => row.billing_status.merchant === "new",
      component: (row) => (
        <UpdateStatus
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "force-sync",
      icon: PlusSquareIcon,
      validation: (row) => row.billing_status.merchant === "new",
      component: (row) => (
        <ForceSync row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "complete-order",
      icon: PlusSquareIcon,
      validation: (row) =>
        (row.status === "successful" || row.status === "unsuccessful") &&
        row.billing_status.merchant === "new",
      component: (row) => (
        <CompleteOrder row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
  ],
  Couriers: [
    {
      id: "tracking-mismatch",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <ExportTrackMismatch
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          type={"courier"}
          isInprogress={row.isMismatchInProgress}
          setInprogress={row.setIsMismatchInProgress}
        />
      ),
    },
    {
      id: "edit-courier",
      icon: PlusSquareIcon,
      validation: (row) => accessLvl === Role.financeManager,
      component: (row) => (
        <AddCourier
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          type={"courier"}
        />
      ),
    },
  ],
  Merchants: [
    {
      id: "create-invoice",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <CreateInvoice row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "add-balance",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <UpdateBalance
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "deduct-balance",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <UpdateBalance
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          isDeduct={true}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "tracking-mismatch",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <ExportTrackMismatch
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          type={"merchant"}
          isInprogress={row.isMismatchInProgress}
          setInprogress={row.setIsMismatchInProgress}
        />
      ),
    },
    {
      id: "edit-merchant",
      icon: PlusSquareIcon,
      validation: (row) => accessLvl === Role.financeManager,
      component: (row) => (
        <AddMerchant
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "assign-merchant",
      icon: PlusSquareIcon,
      validation: (row) => accessLvl === Role.financeManager,
      component: (row) => (
        <AssignPlan
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "update-recommendation",
      icon: PlusSquareIcon,
      validation: (row) =>
        accessLvl === Role.super || accessLvl === Role.financeManager,
      component: (row) => (
        <UpdateRecommendation row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "update-merchant",
      icon: PlusSquareIcon,
      validation: (row) => accessLvl === Role.financeManager,
      component: (row) => (
        <UpdateMerchantFees row={row.row} fetchData={row.fetchData}
          filterQuery={row.filterQuery} setOpenLoader={row.setOpenLoader} />
      ),
    }
  ],
  Invoices: [
    {
      id: "print-invoice",
      icon: PlusSquareIcon,
      validation: (row) =>
        accessLvl === Role.super || accessLvl === Role.superFinance,
      component: (row) => <PrintInvoice row={row.row} />,
    },
    {
      id: "settle-invoice",
      icon: PlusSquareIcon,
      validation: (row) => row.status !== "settled",
      component: (row) => (
        <SettleInvoice row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
  ],
  ProgressOrders: [
    {
      id: "print-label",
      icon: PrintLabelIcon,
      validation: (row) => true,
      component: (row) => (
        <PrintLabel row={row?.row} setOpenLoader={row?.setOpenLoader} />
      ),
    },
    {
      id: "cash-on-delivery",
      icon: ExternalLinkIcon,
      validation: (row) => true,
      component: (row) => (
        <CashOnDeliveryAction
          row={row?.row}
          setOpenLoader={row?.setOpenLoader}
        />
      ),
    },
    {
      id: "update-notes",
      icon: ChatIcon,
      validation: (row) => true,
      component: (row) => (
        <OrderNotes
          row={row?.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "update-weight",
      icon: CalendarIcon,
      validation: (row) => true,
      component: (row) => (
        <UpdateWeight row={row?.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "update-status",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <UpdateStatus
          row={row?.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
    {
      id: "force-sync",
      icon: PlusSquareIcon,
      validation: (row) => true,
      component: (row) => (
        <ForceSync row={row?.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
    {
      id: "complete-order",
      icon: PlusSquareIcon,
      validation: (row) =>
        row?.status === "successful" || row?.status === "unsuccessful",
      component: (row) => (
        <CompleteOrder row={row.row} setOpenLoader={row.setOpenLoader} />
      ),
    },
  ],
  PricingPlans: [
    {
      id: "uodate-status",
      icon: PlusSquareIcon,
      validation: (row) => row.status !== "settled",
      component: (row) => (
        <UpdatePricingPlanStatus
          row={row.row}
          setOpenLoader={row.setOpenLoader}
          fetchData={row.fetchData}
          filterQuery={row.filterQuery} />
      ),
    },
  ], 
  Leads: [{
    id: "convert-to-merchant",
    icon: PlusSquareIcon,
    validation: (row) => accessLvl === Role.financeManager,
    component: (row) => (
      <AddMerchant
        isLead
        row={row.row}
        setOpenLoader={row.setOpenLoader}
        fetchData={row.fetchData}
        filterQuery={row.filterQuery}
      />
    ),
  }]
};

export default TableActions;
