import React from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import { onPrintLabel } from "../../../api/orders";

export function PrintLabel({ row, setOpenLoader }) {
  const toast = useToast();
  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    toast({
      status: "success",
      title: "",
      description: "File Downloaded",
    });
  };

  const onClickImport = async () => {
    setOpenLoader(true);
    const res = await onPrintLabel(row.orderId, toast);
    console.log(res);
    if (res) {
      toast({
        status: "info",
        title: "Downloading File",
        duration: 1000,
      });
      openFileInNewTab(res);
    }
    setOpenLoader(false);
  };
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={() => {
          onClickImport();
        }}
      >
        <Text>Print Label</Text>
      </Box>
    </>
  );
}
