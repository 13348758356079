
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box,
    Text,
    CardBody
} from '@chakra-ui/react';
import React from 'react';
import MainTable from "../Tables/MainTable";

export function DynmaicModal({ row, columns, dataKey, title }) {

    const finalRef = React.useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const onCloseModal = () => {
        onClose();
    }

    return (
        <>
            <Box
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                cursor={"pointer"}
                onClick={onOpen}
                mr={2}
            >
                <Text textDecor={'underline'} fontSize={12} onClick={onOpen}>Show</Text>
            </Box>
            <Modal size={'5xl'} isCentered finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <CardBody bg={"white"} overflowX={{ sm: "scroll" }} pb="0px">
                            <MainTable
                                columns={columns?.filter(col => col.show)}
                                data={row[dataKey]}
                            />
                        </CardBody>
                    </ModalBody>

                </ModalContent>
            </Modal >

        </>
    )
}