import React, { useState } from "react";
import {
  Flex,
  // Button,
  // FormControl,
  // FormLabel,
  // Input,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { login } from "api/login";
import FincartBlueLogo from "assets/icons/FincartBlueLogo";
import { Loader } from "components/Loader/loader";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { loginWithGoogle } from "api/login";

const { REACT_APP_GOOGLE_CLIENT_ID: clientId } = process.env;

function SignIn() {
  const textColor = useColorModeValue(
    "radial-gradient(47.01% 330.41% at 104.25% -161.43%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(60.53% 425.45% at -3.3% 255.71%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(20.19% 237.14% at 68.87% 261.43%, rgb(245, 132, 0) 0%, rgba(245, 132, 0, 0) 100%), rgb(187, 6, 203)",
    "white"
  );
  const bgForm = "gray.50";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useToast();
  const [openLoader, setOpenLoader] = useState(false);

  const handleSubmit = async (event) => {
    setOpenLoader(true);
    event.preventDefault();

    await login(email, password, toast).then((response) => {
      if (response.status === 200) {
        window.location.href = "/";
      } else {
        setErrorMessage("Username or password is incorrect");
      }
    });
    setOpenLoader(false);
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      setOpenLoader(true);
      await loginWithGoogle(credentialResponse.credential, toast);
      window.location.href = "/";
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Unsuccessful login, please try with another google account or try again later"
      );
    } finally {
      setOpenLoader(false);
    }
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Flex
        minH="100vh"
        bg="white" // Page background color
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Loader isLoading={openLoader} />
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          mr={20}
          gap={6}
        >
          <FincartBlueLogo />
          <Flex
            direction="column"
            w={{ base: "90%", md: "400px" }}
            bg={"white"}
            p={8}
            borderRadius="lg"
            boxShadow="lg"
          >
            <Text fontWeight={600} color={textColor} textAlign="center" mb={6}>
              Welcome to Fincart Admin Portal
            </Text>

            <form onSubmit={handleSubmit}>
              {/* <FormControl mb={4}>
                <FormLabel fontWeight={"bold"} fontSize={14} color={textColor}>
                  Email
                </FormLabel>
                <Input
                  onChange={(event) => setEmail(event.target.value)}
                  type="text"
                  placeholder="Email"
                  size="md"
                  focusBorderColor="black"
                />
              </FormControl>

              <FormControl mb={6}>
                <FormLabel fontWeight={"bold"} fontSize={14} color={textColor}>
                  Password
                </FormLabel>
                <Input
                  onChange={(event) => setPassword(event.target.value)}
                  type="password"
                  placeholder="Your password"
                  size="md"
                  focusBorderColor="black"
                />
              </FormControl> */}

              {errorMessage && (
                <Text color="red.500" textAlign="center" mb={4}>
                  {errorMessage}
                </Text>
              )}
              {/* 
              <Button
                type="submit"
                bgColor={"rgb(0 100 250)"}
                color="white"
                size="md"
                w="full"
                isDisabled={!email || !password}
                _hover={{ background: "rgb(0 100 250)" }}
                marginBottom={4}
              >
                Sign In
              </Button> */}
              <GoogleLogin
                width="336"
                onSuccess={handleGoogleLogin}
                onError={console.error}
              />
            </form>
          </Flex>
        </Flex>
      </Flex>
    </GoogleOAuthProvider>
  );
}

export default SignIn;
