import React from "react";
import {
  Image,
  useColorModeValue,
  Tr,
  Td,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Center,
  Link,
} from "@chakra-ui/react";
import { HamburgerIcon, EmailIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";

function TablesTableRow(props) {
  const { columns, row, actions, setOpenLoader, fetchData, filterQuery } = props;
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  return (
    <>
      <Tr>
        {columns?.map((column, index) => {
          return (
            <Td
              position={column.stickyLeft || column.stickyRight ? "sticky" : ""}
              left={column.stickyLeft ? "0" : ""}
              right={column.stickyRight ? "0" : ""}
              p={2}
              bg="white"
              key={index}
              borderColor={borderColor}
            >
              <Flex
                width={column.style.width}
                key={index}
                align="center"
                flexWrap="nowrap"
                justifyContent={"center"}
              >
                {renderContent(column, row, actions, titleColor, setOpenLoader, fetchData, filterQuery)}
              </Flex>
            </Td>
          );
        })}
      </Tr>
    </>
  );
}

function renderContent(column, row, actions, titleColor, setOpenLoader, fetchData, filterQuery) {
  const prepareBigStrings = (string) => {
    return (
      <Tooltip label={string} aria-label="A tooltip">
        {string.substring(0, 15) + "...."}
      </Tooltip>
    );
  };

  switch (column.type) {
    case "link":
      return (
        <Center
          fontSize={"0.8125rem"}
          fontWeight={"500"}
          letterSpacing={"0.03em"}
          pos="relative"
          bgColor="inherit"
          w="100%"
          h="100%"
        >
          <Link
            color="teal.500"
            onClick={() => {
              column?.onClickLink(row);
            }}
          >
            {row[column.name]}
          </Link>
        </Center>
      );

    case "image":
      return (
        <Image
          width="67px"
          height="20px"
          objectFit="contain"
          src={row[column.name]}
          mb="1"
        />
      );

    case "date":
      return (
        <>
          <Text
            lineHeight={"20px"}
            fontWeight={"400"}
            fontSize={"13px"}
            color={titleColor}
          >
            {row[column.name] != "" &&
              dayjs(row[column.name])?.format(
                `ddd, D MMM${dayjs(row[column.name])?.isBefore(dayjs()?.startOf("year"))
                  ? "’" + dayjs(row[column.name])?.format("YY")
                  : ""
                }`
              )}
          </Text>
        </>
      );

    case "date_range":
      return (
        <>
          <Text
            lineHeight={"20px"}
            fontWeight={"400"}
            fontSize={"13px"}
            color={titleColor}
          >
            {row[column.name] != "" &&
              dayjs(row[column.name])?.format(
                `ddd, D MMM${dayjs(row[column.name])
                  ? "   " + dayjs(row[column.name])?.format("YYYY")
                  : ""
                }`
              )}
          </Text>
        </>
      );

    case "status":
      return (
        <>
          <Text
            lineHeight={"20px"}
            fontWeight={"400"}
            fontSize={"13px"}
            color={titleColor}
          >
            {row[column.name] ? "Active" : "Inactive"}
          </Text>
        </>
      );
    case "boolean":
      return row[column.name] ? "Yes" : "No";

    case "toolTip":
      return (
        <>
          <Tooltip label={row[column.name]} fontSize="md">
            {row[column.name] ? <EmailIcon boxSize={4} /> : ""}
          </Tooltip>
        </>
      );

    case "actions":
      return (
        <Menu>
          {actions.some((action) => action.validation(row)) && (
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
              size={"sm"}
            />
          )}
          <MenuList
            border={"1px solid lightgrey"}
            padding={"0px"}
            background={"#EDF2F7"}
            width={"180px"}
            borderRadius={0}
          >
            {actions?.map((action) => {
              if (action.validation) {
                if (!action.validation(row)) {
                  return null;
                }
              }
              return (
                <MenuItem
                  h={"2rem"}
                  fontWeight={500}
                  w={"100%"}
                  padding={1}
                  borderBottom={"1px solid lightgrey"}
                  fontSize={14}
                >
                  <action.component 
                    row={row}
                    setOpenLoader={setOpenLoader}
                    filterQuery={filterQuery}
                    fetchData={fetchData} />
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );

    default:
      return (
        <>
          <Text
            lineHeight={"20px"}
            fontWeight={"400"}
            fontSize={"13px"}
            color={titleColor}
          >
            {row[column.name]?.length > 15
              ? prepareBigStrings(row[column.name].toString())
              : row[column.name]}
          </Text>
        </>
      );
  }
}

export default TablesTableRow;
