import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Input,
  useDisclosure,
  Box,
  Select,
  Switch,
  Text,
  Icon,
  Checkbox,
  Spinner,
  CheckboxGroup
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import { CSVLink } from 'react-csv';
import { Loader } from "../../Loader/loader";
import {
  onAddMerchant,
  getIndustries,
  getCustomization,
  getAreaMappingConfig,
  validateMerchantAreaMapping,
  updateMerchant,
  getMerchantPermissions,
  validateRecommendationFile,
  getUpdateRecommendationConfig,
  downloadRecommendationFileSample,
} from "../../../api/merchants";
import { convertToMerchant } from "api/leads";
import {
  AsyncSelect
} from "chakra-react-select";
import { getPricingPlansData } from "api/pricing-plans";

const fetchPricingPlanOptions = async (query) => {
  query = query.trim();
  const { pricingPlans } = await getPricingPlansData({
    filters: { name: query.length > 0?query: undefined },
    limit: 5,
    page: 1,
  });
  return pricingPlans;
};



export function AddMerchant({ row, fetchData, filterQuery, isLead }) {
  const finalRef = React.useRef(null);
  const csvLink = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;

  const UploadIcon = (props) => (
    <Icon width="24px" height="34px" viewBox="0 0 28 36" fill="none" {...props}>
      <path
        d="M23.1 14H25.7C26.0448 14 26.3754 14.1446 26.6192 14.402C26.863 14.6594 27 15.0085 27 15.3725V33.6275C27 33.9915 26.863 34.3406 26.6192 34.598C26.3754 34.8554 26.0448 35 25.7 35H2.3C1.95522 35 1.62456 34.8554 1.38076 34.598C1.13696 34.3406 1 33.9915 1 33.6275V15.3725C1 15.0085 1.13696 14.6594 1.38076 14.402C1.62456 14.1446 1.95522 14 2.3 14H4.9"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 25.6666V4.66663"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3335 9.33329L14.0002 4.66663L9.66683 9.33329"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      hotline: "",
      email: "",
      prefix: "",
      industry: "",
      brand: "",
      password: "",
      order_creation_behavior: "",
      hide_pickup_location: false,
      permissions: [],
    },
  });

  const { errors, isDirty } = formState;
  const form = watch();
  const onError = (errors) => console.log(errors);
  const [merchantIndustries, setMerchantIndustries] = useState([]);
  const [customization, setCustomization] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [areaMappingConfig, setAreaMappingConfig] = useState({});
  const [areaMappingFile, setAreaMappingFile] = useState("");
  const [areaMappingErrorFile, setAreaMappingErrorFile] = useState("");
  const [courierRecommendationFile, setCourierRecommendationFile] = useState("");
  const [courierRecommendationFileError, setCourierRecommendationFileError] = useState("");
  const getToken = () => {
    return localStorage?.getItem("tokenKey");
  };

  const validateCourierRecommendationFile = async () => {
    const formData = new FormData();
    formData.append('file', courierRecommendationFile);
    const response = await validateRecommendationFile(formData, toast);
    if(response?.errors) throw new Error(response?.errors)
  }
  const validateAreaMappingFile = async () => {
    const formData = {
      file: areaMappingFile
    }
    const response = await validateMerchantAreaMapping(
      formData,
      { "Content-Type": "multipart/form-data", Authorization: getToken() },
      toast
    );
    if(response?.errors) throw new Error(response?.errors)
  }
  const handelSubmit = async (data) => {
    if(isLead){
      setOpenLoader(true);
      const files = {}

      if (form["order_creation_behavior"] === "custom" && areaMappingFile) {
        try{
          files["area-mapping"] = areaMappingFile;
          await validateAreaMappingFile();
        }catch (e){
           setAreaMappingErrorFile(e.message);
           setOpenLoader(false);
           return;
        }
      }

      if (courierRecommendationFile && courierRecommendationFile !== "") {
        try{
          files["courier-recommendation"] = courierRecommendationFile;
          await validateCourierRecommendationFile();
        } catch(e){
          setCourierRecommendationFileError(e.message);
          setOpenLoader(false);
          return;
        }
      }

      const body = {
        ...data,
        ...files
      };
    
      try{
        await convertToMerchant(
          row._id,
          body,
          toast
        );
        onCloseModal();
      } catch(e){
        console.error(e);
      } finally {
        setOpenLoader(false);
      }
    } else if (row) {
      setOpenLoader(true);
      const body = {
        hotline: data.hotline,
        hide_pickup_location: data.hide_pickup_location,
        permissions: data.permissions,
      };
      const res = await updateMerchant(row._id, body, undefined, toast);
      if (res) {
        toast({
          status: "success",
          title: "",
          description: "Updated successfully",
        });
        setOpenLoader(false);
        onCloseModal();
      }
    } else {
      if (form["order_creation_behavior"] === "custom" && !areaMappingFile) {
        toast({
          status: "error",
          title: "Error",
          description: "Area mapping file is required",
        });
      } else {
        setOpenLoader(true);

        let fileErrors = '';
        if (form["order_creation_behavior"] === "custom" && areaMappingFile) {
          const formData = {
            file: areaMappingFile
          }
          const response = await validateMerchantAreaMapping(
            formData,
            { "Content-Type": "multipart/form-data", Authorization: getToken() },
            toast
          );
          fileErrors = response?.errors;
          
          if (fileErrors) {
            setAreaMappingErrorFile(fileErrors);
          }
        }

        if (!fileErrors) {
          const body = {
            ...data,
            ...(form["order_creation_behavior"] === "custom"
              ? { file: areaMappingFile }
              : {}),
          };
          const res = await onAddMerchant(
            body,
            {
              "Content-Type": "multipart/form-data",
              Authorization: getToken(),
            },
            toast
          );

          if (res) {
            onCloseModal();
            toast({
              status: "success",
              title: "",
              description: "Created successfully",
            });
            setOpenLoader(false);
          }
        }
        setOpenLoader(false);
      }
    }
  };

  const onCloseModal = () => {
    reset();
    onClose();
    if(!isLead) fetchData(filterQuery);
  };

  const getIndustriesDropDown = async () => {
    const industries = await getIndustries(toast);
    return industries;
  };

  const getCustomizationDropDown = async () => {
    const Customization = await getCustomization(toast);
    return Customization;
  };

  const getAreaMappingConfiguration = async () => {
    const config = await getAreaMappingConfig(toast);
    return config;
  };

  const getMerchantPermissionsList = async () => {
    const list = await getMerchantPermissions(toast);
    return list;
  };

  useEffect(() => {
    register("name", {
      required: "name is required",
      validate: (value) =>
        value.length > 1 && value.length < 150 ? true : "Invalid name",
    });

    register("phone", {
      required: "phone is required",
      validate: (value) => (value?.length === 11 ? true : "Invalid phone"),
    });

    register("hotline", {});

    register("email", {
      required: "email is required",
    });

    register("prefix", {
      required: "prefix is required",
    });

    register("industry", {
      required: "industry is required",
    });

    register("brand", {
      required: "brand is required",
    });

    register("password", {
      required: "password is required",
      validate: (value) => (value?.length >= 8 ? true : "Invalid password"),
    });

    register("order_creation_behavior", {
      required: "order creation behavior is required",
    });

    register("hide_pickup_location", {});

    register("permissions", []);

    if(isLead){
      register("plan", {})
    }

    if (isOpen) {
      setOpenLoader(true);
      getIndustriesDropDown().then((res) => {
        setMerchantIndustries(res.industries);
      });

      getCustomizationDropDown().then((res) => {
        const modes = [];
        for (const [modeKey, modeValue] of Object.entries(
          res.customization_modes
        )) {
          modes.push({
            label: modeValue,
            value: modeKey,
          });
        }
        setCustomization(modes);
        setValue("order_creation_behavior", "default");
      });

      getAreaMappingConfiguration().then((res) => {
        setAreaMappingConfig(
          {
            acceptedAreaMappingFile: (res || []).find(
              (record) => record.name === "add-area-mapping"
            )["allowed-types"],
            areaMappingSampleFile: (res || []).find(
              (record) => record.name === "add-area-mapping"
            )["file-url"],
          } || {}
        );
        setOpenLoader(false);
      });

      if(isLead) {
        setValue("name", row.name);
        setValue("brand", row.brand);
        setValue("email", row.email);
        setValue("industry", row.industry);
        setValue("phone", row.phone);
        getMerchantPermissionsList().then(({ permissions }) => {
          setValue(
            "permissions",
            permissions.map((name) => ({ name, status: false }))
          );
        });
      } else if (row) {
        setValue("name", row.name);
        setValue("brand", row.brand);
        setValue("email", row.email);
        setValue("hide_pickup_location", row.hide_pickup_location);
        setValue("hotline", row.hotline);
        setValue("industry", row.industry);
        setValue("order_creation_behavior", row.order_creation_behavior);
        setValue("password", row.password);
        setValue("prefix", row.prefix);
        setValue("phone", row.phone);
        setValue("permissions", row.permissions);
      } else {
        getMerchantPermissionsList().then(({ permissions }) => {
          setValue(
            "permissions",
            permissions.map((name) => ({ name, status: false }))
          );
        });
      }
    }
  }, [isOpen]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        {!row && (
          <Button onClick={onOpen}>
            <AddIcon boxSize={4} />
          </Button>
        )}
        {row && (
          <Box onClick={onOpen}>
            <Text>{isLead ? "Convert to merchant": "Edit Merchant"}</Text>
          </Box>
        )}
      </Box>
      <Modal
        size={"5xl"}
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isLead ? "Convert to merchant": !row ? "Add Merchant" : `Edit ${row.name}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDir={"row"} gap={"6rem"}>
            <Loader isLoading={openLoader} />
            <Box w={"40%"} display={"flex"} flexDir={"column"} gap={6}>
              <FormControl isInvalid={!!errors.name} isRequired isDisabled={row?.name}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Name
                </FormLabel>
                <Input
                  placeholder="Enter name"
                  type="text"
                  value={form["name"]}
                  onChange={(e) => {
                    setValue("name", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.phone} isRequired isDisabled={row?.phone}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Phone
                </FormLabel>
                <Input
                  placeholder="Enter Phone"
                  type="number"
                  value={form["phone"]}
                  onChange={(e) => {
                    setValue("phone", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Hotline
                </FormLabel>
                <Input
                  placeholder="Enter Hotline"
                  type="number"
                  value={form["hotline"]}
                  onChange={(e) => {
                    setValue("hotline", e.target.value);
                  }}
                />
              </FormControl>

              <FormControl isInvalid={!!errors.email} isRequired isDisabled={row?.email}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Email
                </FormLabel>
                <Input
                  placeholder="Enter email"
                  type="email"
                  value={form["email"]}
                  onChange={(e) => {
                    setValue("email", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.password} isRequired isDisabled={row?.password}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Password
                </FormLabel>
                <Input
                  placeholder="Enter Password"
                  type="password"
                  value={form["password"]}
                  onChange={(e) => {
                    setValue("password", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors.hide_pickup_location}
                display="flex"
                alignItems="center"
              >
                <FormLabel fontSize={12} fontWeight={"semibold"} mb={0}>
                  Hide Pickup Location
                </FormLabel>
                <Switch
                  defaultValue={false}
                  isChecked={form["hide_pickup_location"]}
                  onChange={(e) =>
                    setValue("hide_pickup_location", e.target.checked)
                  }
                />
              </FormControl>
              {isLead && (
                <FormControl
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <Box w={"55%"}>
                    <FormLabel fontSize={12} fontWeight={"semibold"}>
                      Courier Recommendation File
                    </FormLabel>
                    <Box
                      mt={-2}
                      color={"blue"}
                      fontSize={12}
                      cursor={"pointer"}
                      onClick={async () => {
                        downloadRecommendationFileSample(toast)
                      }}
                    >
                      Download Sample
                    </Box>
                  </Box>

                  <Box display={"flex"} flexDirection={"column"} h={"10rem"}>
                    <div className="app">
                      <div className="parent" style={{ width: "250px" }}>
                        <div className="file-upload">
                          <UploadIcon />
                          {!courierRecommendationFile && (
                            <p>Maximum file size 5mb</p>
                          )}
                          {courierRecommendationFile && (
                            <p>{courierRecommendationFile?.name}</p>
                          )}
                          <input
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              if (!file) {
                                setCourierRecommendationFile("");
                                setCourierRecommendationFileError("");
                              } else if (file.size > 5e6) {
                                toast({
                                  status: "error",
                                  title: "Error.",
                                  description:
                                    "The maximum allowed file size is 5 MB",
                                });
                              } else {
                                setCourierRecommendationFile(file);
                              }
                            }}
                            onClick={(e) => {
                              setCourierRecommendationFileError("");
                              e.target.value = null;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {courierRecommendationFileError && (
                      <Box display={"flex"} justifyContent={"center"} mt={"-2"}>
                        <Box
                          color={"#e64646"}
                          fontSize={14}
                          cursor={"pointer"}
                          onClick={async () => csvLink.current.link.click()}
                        >
                          Download Error
                        </Box>
                        <CSVLink
                          data={courierRecommendationFileError}
                          filename="courier-recommendation-errors.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </Box>
                    )}
                  </Box>
                </FormControl>
              )}
            </Box>

            <Box w={"40%"} display={"flex"} flexDir={"column"} gap={6}>
              <FormControl isInvalid={!!errors.prefix} isRequired isDisabled={row?.prefix}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Prefix
                </FormLabel>
                <Input
                  placeholder="Enter prefix"
                  type="text"
                  value={form["prefix"]}
                  onChange={(e) => {
                    setValue("prefix", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors.prefix?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.brand} isRequired isDisabled={row?.brand}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Brand
                </FormLabel>
                <Input
                  placeholder="Enter brand"
                  type="text"
                  value={form["brand"]}
                  onChange={(e) => {
                    setValue("brand", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors.brand?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.industry} isRequired isDisabled={row?.industry}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Industry
                </FormLabel>

                <Select
                  onChange={(e) => {
                    setValue("industry", e.target.value);
                  }}
                  value={form["industry"]}
                  placeholder="Select Industry"
                >
                  {merchantIndustries?.map((rec, i) => (
                    <option key={i} value={rec}>
                      {rec}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.industry?.message}</FormErrorMessage>
              </FormControl>
              {isLead && (
                <FormControl isInvalid={!!errors.plan}>
                  <FormLabel fontSize={12} fontWeight={"semibold"}>
                    Plan
                  </FormLabel>
                  <AsyncSelect
                    defaultOptions
                    placeholder="Select a plan..."
                    getOptionLabel={(v) => v.name}
                    getOptionValue={(v) => v.id}
                    noOptionsMessage={() => "No results"}
                    loadOptions={fetchPricingPlanOptions}
                    onChange={(plan) => {
                      setValue('plan', plan.id)
                    }}
                  />
                  <FormErrorMessage>
                    {errors.plan?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={!!errors.order_creation_behavior} isDisabled={row?.order_creation_behavior}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Customization
                </FormLabel>

                <Select
                  onChange={(e) => {
                    setAreaMappingFile("");
                    setAreaMappingErrorFile("");
                    setValue("order_creation_behavior", e.target.value);
                  }}
                  value={form["order_creation_behavior"]}
                  placeholder="Select Mode"
                >
                  {customization.map((rec, i) => (
                    <option key={i} value={rec.value}>
                      {rec.label}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>
                  {errors.order_creation_behavior?.message}
                </FormErrorMessage>
              </FormControl>
              {form["order_creation_behavior"] === "custom" && (
                <FormControl
                  isRequired
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <Box w={"55%"} display={"flex"} alignItems={"center"}>
                    <FormLabel fontSize={12} fontWeight={"semibold"}>
                      Area Mapping File
                    </FormLabel>
                    <Box
                      position={"absolute"}
                      mt={"7"}
                      color={"blue"}
                      fontSize={12}
                      cursor={"pointer"}
                      onClick={() => {
                        window.open(
                          baseURL +
                          "/" +
                          areaMappingConfig.areaMappingSampleFile
                        );
                      }}
                    >
                      Download Sample
                    </Box>
                  </Box>

                  <Box display={'flex'} flexDirection={'column'} h={'10rem'}>
                    <div className="app">
                      <div className="parent" style={{ width: "250px" }}>
                        <div className="file-upload">
                          <UploadIcon />
                          {!areaMappingFile && <p>Maximum file size 5mb</p>}
                          {areaMappingFile && <p>{areaMappingFile?.name}</p>}
                          <input
                            type="file"
                            onChange={(e) => {
                              if (
                                areaMappingConfig.acceptedAreaMappingFile.includes(
                                  e.target.files[0]?.type
                                ) &&
                                e.target.files[0]?.size < 5000000
                              ) {
                                setAreaMappingFile(e.target.files[0]);
                              } else
                                toast({
                                  status: "error",
                                  title: "Error.",
                                  description: "Invalid File Type",
                                });
                            }}
                            onClick={(e) => {
                              setAreaMappingErrorFile("");
                              e.target.value = null;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {areaMappingErrorFile && (
                      <Box display={'flex'} justifyContent={'center'} mt={'-2'}>
                        <Box
                          color={"#e64646"}
                          fontSize={14}
                          cursor={"pointer"}
                          onClick={async () => csvLink.current.link.click()}
                        >
                          Download Error
                        </Box>
                        <CSVLink
                          data={areaMappingErrorFile}
                          filename="area-mapping-errors.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </Box>
                    )}
                  </Box>
                </FormControl>
              )}
              <FormControl isInvalid={!!errors.permissions}>
                <FormLabel fontSize={12} fontWeight={"semibold"}>
                  Permissions
                </FormLabel>
                <CheckboxGroup spacing={2}>
                  {form.permissions.map(({ name, status }, index) => {
                    return (
                      <Checkbox
                        w={"50%"}
                        key={index}
                        isChecked={status}
                        onChange={(v) =>
                          setValue(
                            `permissions.${index}.status`,
                            v.target.checked
                          )
                        }
                      >
                        {name}
                      </Checkbox>
                    );
                  })}
                </CheckboxGroup>
                <FormErrorMessage>
                  {errors.permissions?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handelSubmit, onError)}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>{" "}
    </>
  );
}
