import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
  useDisclosure,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { onUpdateOrderWeight } from "../../../api/orders";

export function UpdateWeight({ row, setOpenLoader }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, handleSubmit, formState, setValue, watch, reset } = useForm(
    {
      defaultValues: {
        weight: "",
      },
    }
  );

  useEffect(() => {
    register("weight", {
      required: "weight is required",
      validate: (value) =>
        +value > 1 && +value < 1000 ? true : "Invalid weight",
    });
  }, [isOpen]);

  const { errors, isDirty } = formState;
  const form = watch();
  const toast = useToast();

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const handelSubmit = async (data) => {
    setOpenLoader(true);
    const body = {
      ...data,
      order_number: row.orderNumber,
    };

    const res = await onUpdateOrderWeight(body, toast);
    if (res) {
      toast({
        status: "success",
        title: "",
        description: "Updated successfully",
      });
      onCloseModal();
    }
    setOpenLoader(false);
  };

  const onError = (errors) => console.log(errors);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>Update Weight</Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update weight</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired mb="4" isInvalid={!!errors.weight}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Weight
              </FormLabel>
              <InputGroup>
                <Input
                  placeholder="Enter Weight"
                  type="number"
                  value={form["weight"]}
                  onChange={(e) => setValue("weight", e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>{errors.weight?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color={"white"}
              colorScheme="blue"
              onClick={handleSubmit(handelSubmit, onError)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
