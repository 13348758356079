import { Flex, Input, Text } from "@chakra-ui/react";

const NumberFilter = (props) => {
  const { column, adjustFilters, value } = props;
  return (
    <Flex w={"100%"} direction={"column"} gap={2}>
      <Text fontSize={"13px"} fontWeight={600} lineHeight={"20px"}>
        {column.label}
      </Text>
      <Input
        value={value ?? ""}
        type="number"
        onChange={(e) => adjustFilters(column, e.target.value)}
      />
    </Flex>
  );
};

export default NumberFilter;
