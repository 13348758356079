import { get, post, put, postArrayBuffer, postExport } from "./index";

export const getInvoices = async (query, toast) => {
  return post("/invoice/listing", { ...query }, undefined, toast);
};

export const getInvoicesExport = async (query, toast) => {
  return postExport("/invoice/listing/export", { ...query }, undefined, toast);
};

export const onPrintInvoice = async (id, toast) => {
  return postArrayBuffer(
    "/invoice/orders/generate/" + id,
    undefined,
    undefined,
    toast
  );
};

export const onSettleInvoice = async (id, toast) => {
  return post("/invoice/settle/" + id, undefined, undefined, toast);
};
