import {
  Box,
  useToast,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  Text,
} from "@chakra-ui/react";
import { updatePricingPlanStatus } from "api/pricing-plans";
import React from "react";
export function UpdatePricingPlanStatus({ row, setOpenLoader, fetchData, filterQuery }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();
  const updateStatus = async () => {
    setOpenLoader(true);
    const updateStatusRes = await updatePricingPlanStatus(row.id, toast);
    if (updateStatusRes) {
      toast({
        status: "success",
        title: "Updated successfully.",
      });
      onCloseModal();
    }
    setOpenLoader(false);
  };

  const onCloseModal = () => {
    onClose();
    fetchData(filterQuery);
  };
  
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={() => {
          onOpen();
        }}
      >
        <Text>{row.status === 'active' ? 'deactivate' : 'activate'} Status</Text>
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{row.status === 'active' ? 'Deactivate' : 'Activate'} Status</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to {row.status === 'active' ? 'Deactivate' : 'Activate'} status
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseModal}>
              No
            </Button>
            <Button onClick={updateStatus} colorScheme="red" ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
