export const columns = [
  {
    type: "link",
    name: "name",
    label: "Name",
    searchable: true,
    style: { width: "100%" },
    show: true
  },
  {
    type: "string",
    name: "description",
    label: "Description",
    searchable: true,
    style: { width: "100%" },
    show: true
  },
  {
    type: "dropdown",
    name: "status",
    label: "Status",
    searchable: true,
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' }
    ],
    style: { width: "100%" },
    show: true
  },
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
