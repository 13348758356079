import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Box,
  Text,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { getPricingPlans, assignMerchantToPlan } from "../../../api/merchants";
import { Loader } from "components/Loader/loader";

export function AssignPlan({ row, fetchData, filterQuery }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pricingPlans, setPricingPlans] = useState([]);
  const toast = useToast();
  const [openLoader, setOpenLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      pricingPlan: "",
    },
  });

  const getPricingPlanDropdown = async () => {
    const res = await getPricingPlans(toast);
    return res;
  };

  const { errors, isDirty } = formState;
  const form = watch();
  const onError = (errors) => console.log(errors);

  const handelSubmit = async (data) => {
    setOpenLoader(true);

    const eventSource = await assignMerchantToPlan(
      form["pricingPlan"],
      { merchant_id: row._id },
      toast
    );

    eventSource.stream();
    onCloseModal();
    toast({
      status: "info",
      title: "info.",
      description: "Your request was successfully received. Please note it takes a few minutes to process.",
    });

    eventSource.addEventListener("message", ({ data }) => {
      toast({
        status: "success",
        title: "Success.",
        description: data,
      });
    });
  };

  const onCloseModal = () => {
    reset();
    onClose();
    fetchData(filterQuery);
  };

  useEffect(() => {
    register("pricingPlan", {
      required: "pricing plan is required",
    });
    if (isOpen) {
      setOpenLoader(true);
      getPricingPlanDropdown().then((res) => {
        setPricingPlans(res.pricingPlans);
        setOpenLoader(false);
      });
    }
  }, [isOpen]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>Assign Pricing Plan</Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Pricing Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Loader isLoading={openLoader} />
            <FormControl isInvalid={!!errors.pricingPlan} isRequired>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Pricing Plan
              </FormLabel>
              <Select
                onChange={(e) => {
                  setValue("pricingPlan", e.target.value);
                }}
                value={form["pricingPlan"]}
                placeholder="Select pricing plan"
              >
                {pricingPlans?.map((rec, i) => (
                  <option key={i} value={rec.id}>
                    {rec.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.pricingPlan?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handelSubmit, onError)}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
