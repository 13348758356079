// chakra imports
import { Box } from "@chakra-ui/react";
// core components
import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "views/Pages/SignIn";

export default function Pages(props) {
  return (
    <>
      <Box w="100%">
        <Box w="100%">
          <SignIn></SignIn>
        </Box>
      </Box>
      <Box px="24px" mx="auto" width="1044px" maxW="100%" mt="60px"></Box>
    </>
  );
}
