import React from "react";
import Couriers from "views/Dashboard/Couriers.js";
import Merchants from "views/Dashboard/Merchants.js";
import Invoices from "views/Dashboard/Invoices.js";
import Leads from "views/Dashboard/Leads.js";
import { StatsIcon } from "components/Icons/Icons";
import ImportCenter from "views/Dashboard/import";
import ProgressTracking from "views/Dashboard/progress-tracking";
import { OrderDetails } from "views/Dashboard/order-details";
import orders from "views/Dashboard/Orders";
import { Role } from "./variables/Roles";
import Customers from "views/Dashboard/Customers";
import PricingPlans from "views/Dashboard/pricing-plans";
import PricingPlansDetails from "views/Dashboard/pricing-plans-details";
import CommercialCampaigns from "views/Dashboard/commercial-campaigns";
const accessLvl = localStorage.getItem("role")
  ? localStorage.getItem("role")
  : "";

var dashRoutes = [
  {
    path: "/orders",
    name: "Orders",
    icon: <StatsIcon color="inherit" />,
    component: orders,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/couriers",
    name: "Couriers",
    icon: <StatsIcon color="inherit" />,
    component: Couriers,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/merchants",
    name: "Merchants",
    icon: <StatsIcon color="inherit" />,
    component: Merchants,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: <StatsIcon color="inherit" />,
    component: Invoices,
    layout: "/admin",
    validation: () => accessLvl === Role.super || accessLvl === Role.superFinance,
  },
  {
    path: "/leads",
    name: "Leads",
    icon: <StatsIcon color="inherit" />,
    component: Leads,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/import",
    name: "Import",
    icon: <StatsIcon color="inherit" />,
    component: ImportCenter,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/progress-tracking",
    name: "Progress Tracking",
    icon: <StatsIcon color="inherit" />,
    component: ProgressTracking,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/customers",
    name: "Customers",
    icon: <StatsIcon color="inherit" />,
    component: Customers,
    layout: "/admin",
    validation: () => true,
  },
  {
    path: "/pricing-plans",
    name: "Pricing Plans",
    icon: <StatsIcon color="inherit" />,
    component: PricingPlans,
    layout: "/admin",
    validation: () =>
      accessLvl === Role.superFinance || accessLvl === Role.financeManager,
  },
  {
    path: "/commercial-campaigns",
    name: "Commercial Campaigns",
    icon: <StatsIcon color="inherit" />,
    component: CommercialCampaigns,
    layout: "/admin",
    validation: () =>
      accessLvl === Role.superFinance || accessLvl === Role.financeManager,
  },
  {
    path: "/orders/:id/details",
    name: "Order Details",
    icon: <StatsIcon color="inherit" />,
    component: OrderDetails,
    layout: "/admin",
    hide: true,
    validation: () => true,
  },
  {
    path: "/pricing-plans/:id/details",
    name: "Pricing Plans Details",
    icon: <StatsIcon color="inherit" />,
    component: PricingPlansDetails,
    layout: "/admin",
    hide: true,
    validation: () => accessLvl === Role.superFinance || accessLvl === Role.financeManager,
  },
  {
    path: "/merchants/:id/plan-details",
    name: "Merchant Pricing Plan Details",
    icon: <StatsIcon color="inherit" />,
    component: PricingPlansDetails,
    layout: "/admin",
    hide: true,
    validation: () => accessLvl === Role.superFinance || accessLvl === Role.financeManager,
  }
];
export default dashRoutes;
