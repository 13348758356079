import axios from "axios";

const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;

const axiosInstance = axios.create({
  baseURL: `${baseURL}/login`,
});

export const login = async (email, password, toast) => {
  try {
    const res = await axiosInstance.post("", { email, password });

    const { token, role } = res.data.body.data;

    if (token) {
      localStorage.setItem("tokenKey", token);
    } else {
      console.error("Token not found in response");
    }

    if (role) {
      localStorage.setItem("role", role);
    } else {
      console.error("Role not found in response");
    }

    return res;
  } catch (err) {
    toast({
      status: "error",
      title: err.response?.data?.msg ? err.response?.data?.msg : "Login failed",
      duration: 1000,
    });
    return err;
  }
};

export const loginWithGoogle = async (credential, toast) => {
  const res = await axiosInstance.post("/google", { credential });

  if (res.status !== 200) {
    throw new Error("Unsuccessful login");
  }

  const { token, role } = res.data.body.data;

  if (token) {
    localStorage.setItem("tokenKey", token);
  } else {
    throw new Error("Token not found in response");
  }

  if (role) {
    localStorage.setItem("role", role);
  } else {
    throw new Error("Role not found in response");
  }

  return res;
};
