import React, { useEffect, useState } from "react";
import { Text, Flex, Box } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateFilter = (props) => {
  const { column, adjustFilters, value } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (value) {
      if (column.type === "date") {
        setStartDate(new Date(value));
      } else {
        setStartDate(new Date(value.from));
        if (value.to && value.to !== -1) setEndDate(new Date(value.to));
      }
    } else {
      if (column.type === "date") {
        setStartDate(null);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }
  }, [value]);

  const onDateRangeChange = (dates) => {
    if (dates[0] || dates[1]) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      start?.setHours(3, 0, 0, 0);
      end?.setHours(3, 0, 0, 0);
      const startTimeStamp = start?.getTime()
      const endTimeStamp = end?.getTime() ?? -1;
      const datesTamp = { from: startTimeStamp, to: endTimeStamp };
      adjustFilters(column, datesTamp);
    } else adjustFilters(column, []);

  };

  const customDateRangeInput = (
    <Box border="1px solid #eee" borderRadius="md" p="2">
      {startDate || endDate ? (
        <Text fontSize={"13px"} fontWeight={600} lineHeight={"20px"}>
          {startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()}
        </Text>
      ) : (
        <Text fontSize={"13px"} lineHeight={"20px"}>
          dd/mm/yyyy
        </Text>
      )}
    </Box>
  );

  return (
    <Flex direction="column">
      <Text fontSize={"13px"} fontWeight={600} lineHeight={"20px"}>
        {column.label}
      </Text>
      {column.type === "date_range" ? (
        <DatePicker
          selected={startDate}
          onChange={onDateRangeChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          placeholderText=" dd/mm/yyyy"
          customInput={customDateRangeInput}
          isClearable
        />
      ) : (
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            adjustFilters(column, date?.getTime());
          }}
          showIcon
          isClearable
        />
      )}
    </Flex>
  );
};

export default DateFilter;
