export const columns = [
  {
    type: "string",
    name: "name",
    label: "Name",
    searchable: false,
    style: { width: "100%" },
    show: true
  },
  {
    type: "image",
    name: "logo",
    label: "Logo",
    searchable: false,
    style: { width: "100%" },
    show: true
  },
  {
    type: "status",
    name: "active",
    label: "Status",
    searchable: false,
    style: { width: "100%" },
    show: true
  },
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
