import { useState, useEffect } from "react";
import { useToast, Card, filter } from "@chakra-ui/react";
import ListContainer from "components/Wrappers/ListContainer";
import tableActions from "variables/tableActions";
import { columns } from "variables/ordersData";
import { getOrders, getCities } from "api/orders";
import { getCouriers } from "api/orders";
import { getStatus } from "api/orders";
import { getSubStatus } from "api/orders";
import { getRejectedReasons } from "api/orders";
import { ExportAction } from "../../components/actions/global/export";
import { getOrdersExport } from "api/orders";
import { Loader } from "components/Loader/loader";
function orders() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
    sortAttribute: "",
    sortDirection: "",
  });

  const [openLoader, setOpenLoader] = useState(false);

  const toast = useToast();

  const [ordersData, setOrdersData] = useState([]);

  const onExport = async () => {
    setOpenLoader(true);
    const exportQuery = { ...filterQuery };
    delete exportQuery?.page;
    delete exportQuery?.limit;
    const res = await getOrdersExport(exportQuery, toast);
    openFileInNewTab(res);
    setOpenLoader(false);
  };


  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = 'orders.csv';
    a.click();
    URL.revokeObjectURL(fileURL);
  };


  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getOrders(query, toast);
    if (res) {
      setOrdersData(res.orders);
      setTotalCount(res.ordersCount);
    }
    setIsLoading(false);
  };

  const globalActions = [
    {
      name: "export",
      component: () => <ExportAction onclickExport={onExport} />,
      validation: () => true,
    },
  ];

  const getDropDowns = async () => {
    const citiesRes = await getCities(toast);
    const couriersRes = await getCouriers(toast);
    const statusRes = await getStatus(toast);
    const subStatusRes = await getSubStatus(toast);
    const rejectedReasonRes = await getRejectedReasons(toast);
    const cityCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "customer_city"
    );
    const courierCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "courier"
    );
    const statusCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "status"
    );
    const subStatusCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "sub_status"
    );
    const rejectionReasonCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "rejection_reason"
    );
    cityCol.options = citiesRes.govs;
    courierCol.options = couriersRes.couriers;
    statusCol.options = statusRes.statuses;
    subStatusCol.options = subStatusRes.subStatuses;
    rejectionReasonCol.options = rejectedReasonRes.rejectionReasons;
  };

  useEffect(() => {
    fetchData(filterQuery);
    columns.find((col) => col.type === "link")["onClickLink"] = (row) => {
      window.open("/admin/orders/" + row.orderId + "/details", "_blank");
    };
    getDropDowns();
  }, [filterQuery.page]);

  return (
    <>
      <Loader isLoading={openLoader} />

      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <ListContainer
          title="Orders"
          columns={columns}
          data={ordersData}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          totalCount={totalCount}
          tableActions={tableActions["Orders"]}
          hasPagination={true}
          fetchData={fetchData}
          isLoading={isLoading}
          globalActions={globalActions}
        />
      </Card>
    </>
  );
}


export default orders








