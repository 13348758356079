import { get, post } from "./index";

export const getCutomers = async (query, toast) => {
  return post("/customer/listing", { ...query }, undefined, toast);
};

export const getCutomerMerchant = async (id, toast) => {
  return get("/customer/" + id + "/merchants", undefined, undefined, toast);
};

