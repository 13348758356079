import { CircularProgress } from '@chakra-ui/react'

export const Loader = ({ isLoading = false }) => {
    const loaderStyle = {
        position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
    };
    return (
        isLoading && (
            <div style={{ ...loaderStyle}}>
                <CircularProgress isIndeterminate  />
            </div>
            )
      
    );
  };