import TextFilter from "./TextFilter";
import NumberFilter from "./NumberFilter";
import DropDownFilter from "./DropDownFilter";
import MultiDropDownFilter from "./MultiDropDownFilter";
import DateFilter from "./DateFilter";
import { Flex } from "@chakra-ui/react";

const ColumnFilters = (props) => {
  const { columns, filterQuery, setFilterQuery } = props;
  const adjustFilters = (column, value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value.length === 0
    ) {
      delete filterQuery.filters[
        column?.filterKey ? column?.filterKey : column?.name
      ];
      setFilterQuery({
        ...filterQuery,
      });
    } else {
      setFilterQuery({
        ...filterQuery,
        filters: {
          ...filterQuery.filters,
          [column?.filterKey ? column?.filterKey : column?.name]: value,
        },
      });
    }
  };
  return columns.map((column) => {
    switch (column.type) {
      case "string":
        return (
          <Flex key={column.name}>
            <TextFilter
              key={column.name}
              column={column}
              value={
                filterQuery.filters[
                column?.filterKey ? column?.filterKey : column?.name
                ]
              }
              adjustFilters={adjustFilters}
            />
          </Flex>
        );
      case "number":
        return (
          <Flex key={column.name}>
            <NumberFilter
              key={column.name}
              column={column}
              value={
                filterQuery.filters[
                column?.filterKey ? column?.filterKey : column?.name
                ]
              }
              adjustFilters={adjustFilters}
            />
          </Flex>
        );
      case "dropdown":
        return (
          <Flex key={column.name}>
            <DropDownFilter
              key={column.name}
              column={column}
              value={
                filterQuery.filters[
                column?.filterKey ? column?.filterKey : column?.name
                ]
              }
              adjustFilters={adjustFilters}
            />
          </Flex>
        );
      case "multi_select":
        return (
          <Flex key={column.name}>
            <MultiDropDownFilter
              key={column.name}
              column={column}
              value={
                filterQuery.filters[
                column?.filterKey ? column?.filterKey : column?.name
                ]
              }
              adjustFilters={adjustFilters}
            />
          </Flex>
        );
      case "date":
      case "date_range":
        return (
          <DateFilter
            key={column.name}
            column={column}
            value={
              filterQuery.filters[
              column?.filterKey ? column?.filterKey : column?.name
              ]
            }
            adjustFilters={adjustFilters}
          />
        );
      default:
        return (
          <Flex key={column.name}>
            <TextFilter
              key={column.name}
              column={column}
              value={
                filterQuery.filters[
                column?.filterKey ? column?.filterKey : column?.name
                ]
              }
              adjustFilters={adjustFilters}
            />
          </Flex>
        );
    }
  });
};

export default ColumnFilters;
