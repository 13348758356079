import { postArrayBuffer, post } from "./index";

export const addEmployee = async (body, toast) => {
  return post("/add_ops", { ...body }, undefined, toast);
};

export const exportDueDiligence = async (toast) => {
  return postArrayBuffer(
    "/order/due-diligence/export",
    undefined,
    undefined,
    toast
  );
};
