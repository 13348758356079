import { useToast, Box, Tooltip, Flex, Button } from "@chakra-ui/react";
import React from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import { exportDueDiligence } from "../../../api/admin";
export function ExportDueDiligence() {
  const toast = useToast();

  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const exportOrders = () => {
    toast({
      status: "info",
      title: "Downloading File",
      duration: 1000,
    });
    exportDueDiligence(toast).then((res) => {
      openFileInNewTab(res);
      toast({
        status: "success",
        title: "",
        description: "File Downloaded",
      });
    });
  };
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      cursor={"pointer"}
      justifyContent={"center"}
      onClick={exportOrders}
    >
      <Tooltip label="Export due diligence">
        <Button pl={4} variant="unstyled" color={"#A0AEC0"}>
          <Flex fontSize={12} gap={2}>
            <DownloadIcon boxSize={3} />
            Export due diligence
          </Flex>
        </Button>
      </Tooltip>
    </Box>
  );
}
