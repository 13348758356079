export const columns = [
  {
    type: "link",
    name: "orderNumber",
    label: "Order code",
    searchable: true,
    style: { width: "15rem" },
    stickyLeft: true,
    show: true
  },
  {
    type: "string",
    name: "customer_name",
    label: "Consignee name",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "customer_phone",
    label: "Consignee phone",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "text",
    name: "first_out_for_delivery_date",
    label: "Fist out for delivery date",
    searchable: false,
    style: { width: "13rem" },
    show: true
  },
  {
    type: "text",
    name: "last_status_update_date",
    label: "Last status update",
    searchable: false,
    style: { width: "13rem" },
    show: true
  },
  {
    type: "date_range",
    name: "processed_on",
    label: "Processing date",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "customer_city",
    label: "Consignee city",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date_range",
    name: "creation_date",
    label: "Creation date",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "courier",
    label: "Courier",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    filterKey: "courier_ids",
    show: true
  },
  {
    type: "string",
    name: "externalId",
    label: "Tracking Number",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "number",
    name: "shipment_age",
    label: "Shipment Age",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "status",
    label: "Main status",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "sub_status",
    label: "Sub status",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "rejection_reason",
    label: "Rejection reason",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "toolTip",
    name: "support_note",
    label: "Support Note",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date_range",
    name: "end_date",
    label: "End status date",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
