import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  useDisclosure,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { onUpdateOrderStatus } from "../../../api/orders";

export function UpdateStatus({ row, setOpenLoader, fetchData, filterQuery }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      status: "",
      subStatus: "",
      rejectionReason: "",
    },
  });

  const { errors, isDirty } = formState;
  const form = watch();

  const handelSubmit = async (data) => {
    setOpenLoader(true);
    const body = {
      sub_status: data.subStatus,
      status: data.status,
      rejection_reason: data.rejectionReason,
      order_number: row.orderNumber,
    };
    const res = await onUpdateOrderStatus(body, toast);
    if (res) {
      onCloseUpdateStatus();
      toast({
        status: "success",
        title: "",
        description: "Updated successfully",
      });
    }
    setOpenLoader(false);
  };

  const onError = (errors) => console.log(errors);

  const onCloseUpdateStatus = () => {
    reset();
    onClose();
    fetchData(filterQuery);
  };

  useEffect(() => {
    register("status", { required: "status is required" });
    register("subStatus", { required: "sub status is required" });
    register("rejectionReason");
    if (row) {
      setValue("status", row.status);
      setValue("subStatus", row.sub_status);
      setValue("rejectionReason", row.rejection_reason);
    }
  }, [isOpen]);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>Update Status</Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseUpdateStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDir={"column"} gap={8}>
            <FormControl
              isInvalid={!!errors.status}
              isRequired
              onChange={(e) => {
                setValue("status", e.target.value);
                setValue("subStatus", "");
                setValue("rejectionReason", "");
              }}
            >
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Status
              </FormLabel>
              <Select value={form["status"]} placeholder="Select option">
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
                <option value="successful">Successful</option>
                <option value="unsuccessful">UnSuccessful</option>
                <option value="cancelled">Cancelled</option>
              </Select>
              <FormErrorMessage>{errors.status?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors.subStatus}
              isRequired
              onChange={(e) => {
                if (e.target.value === "failed attempt")
                  register("rejectionReason", {
                    required: "rejection reason is required",
                  });
                else unregister("rejectionReason");

                setValue("subStatus", e.target.value);
                setValue("rejectionReason", "");
              }}
            >
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Sub Status
              </FormLabel>
              <Select value={form["subStatus"]} placeholder="Select option">
                {form["status"] === "pending" && (
                  <option value="new">New</option>
                )}

                {form["status"] === "processing" && (
                  <option value="picked up">Picked Up</option>
                )}

                {form["status"] === "processing" && (
                  <option value="at courier hub">At Courier Hub</option>
                )}

                {form["status"] === "processing" && (
                  <option value="out for delivery">Out For Delivery</option>
                )}

                {form["status"] === "processing" && (
                  <option value="failed attempt">Failed Attempt</option>
                )}

                {form["status"] === "successful" && (
                  <option value="delivered to customer">
                    Delivered To Customer
                  </option>
                )}

                {form["status"] === "unsuccessful" && (
                  <option value="problematic">Problematic</option>
                )}

                {form["status"] === "unsuccessful" && (
                  <option value="delivery failed & returned to merchant">
                    Delivery Failed Returned To Merchant
                  </option>
                )}

                {form["status"] === "cancelled" && (
                  <option value="cancelled">Cancelled</option>
                )}
              </Select>
              <FormErrorMessage>{errors.subStatus?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isDisabled={form["subStatus"] !== "failed attempt"}
              isRequired={form["subStatus"] === "failed attempt"}
              isInvalid={!!errors.rejectionReason}
              onChange={(e) => {
                setValue("rejectionReason", e.target.value);
              }}
            >
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Rejection Reason
              </FormLabel>
              <Select
                value={form["rejectionReason"]}
                placeholder="Select option"
              >
                <option value="no answer">No Answer</option>
                <option value="rejected">Rejected</option>
                <option value="to be returned">To Be Returned</option>
                <option value="rescheduled">Rescheduled</option>
                <option value="out of zone">Out Of Zone</option>
                <option value="action required">Action Required</option>
              </Select>
              <FormErrorMessage>
                {errors.rejectionReason?.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseUpdateStatus}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color={"white"}
              colorScheme="blue"
              onClick={handleSubmit(handelSubmit, onError)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
