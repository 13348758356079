import { get, getArrayBuffer, post, put, postExport } from "./index";

export const getOrders = async (query, toast) => {
  return post("/order/listing", { ...query }, undefined, toast);
};

export const getOrdersExport = async (query, toast) => {
  return postExport("/order/listing/export", { ...query }, undefined, toast);
};

export const onUpdateCashOnDelivery = async (body, toast) => {
  return post("/order/cod", body, undefined, toast);
};

export const onUpdateOrderNotes = async (body, toast) => {
  return post("/order/note", body, undefined, toast);
};

export const onUpdateOrderStatus = async (body, toast) => {
  return put("/order/status", body, undefined, toast);
};

export const onUpdateOrderWeight = async (body, toast) => {
  return post("/order/weight", body, undefined, toast);
};

export const onPrintLabel = async (query, toast) => {
  return getArrayBuffer("/order/awb?order_ids=", query, undefined, toast);
};

export const onForceOrdersSync = async (body, toast) => {
  return put("/order/force-sync", body, undefined, toast);
};

export const onCompleteOrder = async (body, toast) => {
  return put("/order/billing", body, undefined, toast);
};

export const getCities = async (toast) => {
  return get("/lookups/governorates/v2", undefined, undefined, toast);
};

export const getCouriers = async (toast) => {
  return get("/lookups/couriers?country=EG", undefined, undefined, toast);
};

export const getStatus = async (toast) => {
  return get("/lookups/order/statuses", undefined, undefined, toast);
};

export const getSubStatus = async (toast) => {
  return post("/lookups/order/sub-statuses", {}, undefined, toast);
};

export const getRejectedReasons = async (toast) => {
  return post("/lookups/order/rejection-reasons", {}, undefined, toast);
};

export const getOrderDetailsLogs = async (orderId, toast) => {
  return get("/order/" + orderId + "/logs", undefined, undefined, toast);
};

export const getOrderDetails = async (orderId, toast) => {
  return get("/order/" + orderId + "/details", undefined, undefined, toast);
};
