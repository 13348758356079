export const columns = [
    {
        type: 'multi_select',
        name: 'city',
        label: 'Governorate',
        searchable: true,
        style: { width: "10rem" },
        show: true,
        options: [],
        filterKey: "cities"
    },
    {
        type: 'multi_select',
        name: 'area',
        label: 'Area',
        searchable: true,
        style: { width: "10rem" },
        show: true,
        options: [],
        disabled: true,
        filterKey: "areas"
    },
    {
        type: 'multi_select',
        name: 'district',
        label: 'District',
        searchable: true,
        style: { width: "10rem" },
        show: true,
        options: [],
        disabled: true,
        filterKey: "districts"
    },
    {
        type: 'multi_select',
        name: 'courier',
        label: 'Courier',
        searchable: true,
        options: [],
        style: { width: "10rem" },
        show: true,
        options: [],
        filterKey: "courier_ids",
    },

    {
        type: 'number',
        name: 'merchant_delivery_fees',
        label: 'Merchant Fee',
        searchable: false,
        style: { width: "10rem" },
        show: true
    },
    {
        type: 'number',
        name: 'merchant_rto_fees',
        label: 'RTO',
        searchable: false,
        style: { width: "10rem" },
        show: true
    },
    {
        type: 'popup',
        name: 'extra_weight_threshold',
        label: 'Weight Threshold',
        searchable: false,
        style: { width: "10rem" },
        show: true
    },
    {
        type: 'popup',
        name: 'amount_tiers',
        label: 'Amount Tiers',
        searchable: false,
        style: { width: "10rem" },
        show: true
    },
];

export const amoutTiersColumns = [
    { type: 'number', name: 'from', label: 'From', searchable: false, style: { width: "100%" }, show: true },
    { type: 'number', name: 'to', label: 'To', searchable: false, style: { width: "100%" }, show: true },
    { type: 'number', name: 'flat_rate', label: 'Flat Rate', searchable: false, style: { width: "100%" }, show: true },
    { type: 'string', name: 'type', label: 'Type', searchable: false, style: { width: "100%" }, show: true },
    { type: 'number', name: 'percentage', label: '%', searchable: false, style: { width: "100%" }, show: true },
    { type: 'string', name: 'is_accumulative', label: '% Accumulative', searchable: false, style: { width: "100%" }, show: true }
]
