import React from "react";
import { createRoot } from "react-dom/client";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import theme from "theme/theme.js";
import { ChakraProvider, IconButton } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import CrossIcon from "./assets/icons/CrossIcon";

const userToken = localStorage.getItem("tokenKey");

const ProtectedRoute = ({ element: Component, ...rest }) => {
  return userToken ? <Component {...rest} /> : <Navigate to="/auth/signin" />;
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route
          path="/admin/*"
          element={<ProtectedRoute element={AdminLayout} />}
        />
        <Route path="*" element={<Navigate to="/admin/orders" />} />
      </Routes>
    </BrowserRouter>
    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        duration: 3500,
        success: {
          style: {
            background: "white",
          },
        },
        error: {
          style: {
            background: "#FEF9FA",
          },
        },
      }}
      containerStyle={{
        top: 55,
        left: 20,
        bottom: 20,
        right: 20,
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ message }) => (
            <>
              {message}
              {t.type !== "loading" && (
                <IconButton onClick={() => toast.dismiss(t.id)}>
                  <CrossIcon />
                </IconButton>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  </ChakraProvider>
);
