import React, { useState, useEffect } from 'react';
import { useParams, useMatch } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Box, Card, useToast, Flex, Text, Button } from '@chakra-ui/react';
import { amoutTiersColumns, columns } from '../../variables/pricingPlansDetails';
import { getPricingPlansDetails } from "api/pricing-plans";
import { Loader } from 'components/Loader/loader';
import { DynmaicModal } from 'components/Layout/dynamicModal';
import { PricingPlanWeightDetails } from './pricingPlanWeightDetails';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Pagination from 'components/Wrappers/Pagination';
import Filter from 'components/Filters/Filter';
import { getGovernorates, getAreas, getDistricts, getCouriers, updatePricingPlanStatus } from 'api/pricing-plans';
import { getMerchantPlanDetails } from 'api/merchants';
import './styless.css';

const PricingPlansDetails = () => {
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [listColumns, setListColumns] = useState([...columns])
  const [totalCount, setTotalCount] = useState();
  const { id } = useParams();
  const toast = useToast();
  const isMerchantRoute = !!useMatch('/admin/merchants/:id/plan-details');
  const [merchantBrand, setMerchantBrand] = useState();

  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
    sortAttribute: "",
    sortDirection: "",
  });
  const [data, setData] = useState();

  const fetchData = async (query) => {
    setIsLoading(true);
    let res;
    if (isMerchantRoute) {
      res = await getMerchantPlanDetails(id, query, toast);
      setMerchantBrand(res?.merchantBrand);
    } else {
      res = await getPricingPlansDetails(id, query, toast);
    }
    if (res) {
      let rows = [];
      Object.keys(res.pricingPlanDetails).forEach(gov => {
        const areas = res.pricingPlanDetails[gov];
        Object.keys(areas).forEach(area => {
          Object.keys(areas[area]).forEach(dist => {
            let row = {}
            if (areas[area][dist]) {
              row = areas[area][dist];
              rows.push(...row)
            }
          })
        })
      })
      setData([...rows]);
      setTotalCount(rows.count);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterQuery.page && !filterQuery.filters.cities && !filterQuery.filters.areas) {
      fetchData(filterQuery);
      getDropDowns();
      getCouriersDropdown()
    }
    if (filterQuery.filters.cities && !filterQuery.filters.areas)
      getCityesDropdown();

    if (!filterQuery.filters.cities) {
      const updatedListColumns = listColumns.map(col => {
        if (col.type === "multi_select" && col.name === "district") {
          return {
            ...col,
            options: [],
            disabled: true
          };
        }
        return col;
      });
      setListColumns(updatedListColumns);
    }

    if (filterQuery.filters.areas)
      getDistrictsDropdown();

    if (!filterQuery.filters.areas) {
      const updatedListColumns = listColumns.map(col => {
        if (col.type === "multi_select" && col.name === "district") {
          return {
            ...col,
            options: [],
            disabled: true
          };
        }
        return col;
      });
      setListColumns(updatedListColumns);
    }

  }, [filterQuery.page, filterQuery.filters.cities, filterQuery.filters.areas]);

  const getDropDowns = async () => {
    const citiesRes = await getGovernorates(toast);
    const cityCol = listColumns.find(
      (col) => col.type === "multi_select" && col.name === "city"
    );
    cityCol.options = citiesRes.govs.map(rec => { return { value: rec, label: rec } });
  };

  const getCityesDropdown = async () => {
    const areaRes = await getAreas({ filters: { cities: filterQuery?.filters?.cities?.map(record => record.value) } }, toast);
    const updatedListColumns = listColumns.map(col => {
      if (col.type === "multi_select" && col.name === "area") {
        return {
          ...col,
          options: areaRes.areas.map(rec => ({ value: rec, label: rec })),
          disabled: filterQuery.filters?.cities?.length > 0 ? false : true
        };
      }
      return col;
    });
    setListColumns(updatedListColumns);
  }

  const getDistrictsDropdown = async () => {
    const districtsRes = await getDistricts({ filters: { cities: filterQuery?.filters?.cities?.map(record => record.value), areas: filterQuery?.filters?.areas?.map(record => record.value) } }, toast);
    const updatedListColumns = listColumns.map(col => {
      if (col.type === "multi_select" && col.name === "district") {
        return {
          ...col,
          options: districtsRes.districts.map(rec => ({ value: rec, label: rec })),
          disabled: filterQuery.filters?.cities?.length > 0 ? false : true
        };
      }
      return col;
    });
    setListColumns(updatedListColumns);
  }

  const getCouriersDropdown = async () => {
    const couriersRes = await getCouriers({ filters: { active: true } }, toast);
    const courierCol = listColumns.find(
      (col) => col.type === "multi_select" && col.name === "courier"
    );
    courierCol.options = couriersRes.couriers.map(rec => { return { value: rec.id, label: rec.name } });
  }

  const AmountTiersData = (rowData) => {
    return (
      <DynmaicModal row={rowData} columns={amoutTiersColumns} dataKey={'amount_tiers'} title={'Amount Tiers'} />
    );
  };

  const weightDetails = (rowData) => {
    return (
      <PricingPlanWeightDetails row={rowData} />
    );
  };

  const checkStatus = (pricingPlan) => {
    return pricingPlan?.status === 'active' ? 'Deactivate' : 'Activate'
  }

  const updateStatus = async (pricingPlan) => {
    const updateStatusRes = await updatePricingPlanStatus(pricingPlan.id, toast);
    if (updateStatusRes) {
      toast({
        status: "success",
        title: "Updated successfully.",
      });
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <CardHeader bg={"white"}>
          <Flex
            paddingLeft={2}
            paddingBottom={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex flexDir={"column"}>
              <Text fontSize="xl" fontWeight="bold">
                {merchantBrand} Pricing Plan Details
              </Text>
            </Flex>
            <Box display={'flex'} gap={2} alignItems={'center'}>
              {data && data[0] && !isMerchantRoute && (
                <Button size={'sm'} onClick={() => {
                  updateStatus(data[0])
                }}  >{checkStatus(data[0])} Plan</Button>
              )}
              <Filter
                columns={listColumns.filter(col => col.searchable)}
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
                fetchData={fetchData}
              />
            </Box>
          </Flex>
          <Pagination filterQuery={filterQuery} setFilterQuery={setFilterQuery} totalCount={totalCount} />


        </CardHeader>
        <CardBody bg={"white"} overflowX={{ sm: "scroll" }} pb="0px">
          <Box minWidth="50rem" p={4}>
            <DataTable filterDisplay="row" outlineColor="gray.50" value={data} rowGroupMode="rowspan" groupRowsBy={["area", "city", "district"]}
              tableStyle={{ minWidth: '50rem' }}>
              {listColumns.filter(col => col.type !== 'popup').map((col, index) =>
                <Column showFilterMenu={false}
                  color={'red'}
                  key={index}
                  filterField={col.name} field={col.name} header={col.label} style={col.style} headerClassName='header-class' bodyClassName='body-class'></Column>
              )}
              <Column bodyClassName='body-class' headerClassName='header-class' style={listColumns.find(col => col.name === 'amount_tiers').style} field='amount_tiers' header="Amount Tiers" body={AmountTiersData}></Column>
              <Column bodyClassName='body-class' headerClassName='header-class' style={listColumns.find(col => col.name === 'extra_weight_threshold').style} field='extra' header="Extra Weight" body={weightDetails} ></Column>
            </DataTable>
          </Box>
        </CardBody>
      </Card>

    </>
  );
};

export default PricingPlansDetails;
