import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  useToast,
  useDisclosure,
  Box,
  Text,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SSE } from "sse.js";

import { Loader } from "components/Loader/loader";

import {
  getUpdateRecommendationConfig,
  validateRecommendationFile,
} from "../../../api/merchants";

const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;

export function UpdateRecommendation({ row }) {
  const EventSource = SSE;
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [recommendationSample, setRecommendationSample] = useState();
  const [acceptedImportFile, setAcceptedImportFile] = useState();
  const [importFile, setImportFile] = useState();
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isImportDisabled, setIsImportDisabled] = useState(true);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [isValidateDisabled, setIsValidateDisabled] = useState(true);
  const [openLoader, setOpenLoader] = useState(false);
  const getToken = () => {
    return localStorage?.getItem("tokenKey");
  };


  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const onCloseModal = () => {
    onClose();
    setImportFile();
  };

  const UploadIcon = (props) => (
    <Icon width="24px" height="34px" viewBox="0 0 28 36" fill="none" {...props}>
      <path
        d="M23.1 14H25.7C26.0448 14 26.3754 14.1446 26.6192 14.402C26.863 14.6594 27 15.0085 27 15.3725V33.6275C27 33.9915 26.863 34.3406 26.6192 34.598C26.3754 34.8554 26.0448 35 25.7 35H2.3C1.95522 35 1.62456 34.8554 1.38076 34.598C1.13696 34.3406 1 33.9915 1 33.6275V15.3725C1 15.0085 1.13696 14.6594 1.38076 14.402C1.62456 14.1446 1.95522 14 2.3 14H4.9"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 25.6666V4.66663"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3335 9.33329L14.0002 4.66663L9.66683 9.33329"
        stroke="#7F7D80"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );

  const validate = () => {
    setOpenLoader(true);
    if (importFile) {
      const formData = new FormData();
      formData.append("file", importFile);
      setIsValidateLoading(true);
      validateRecommendationFile(formData, toast).then((res) => {
        setIsValidateLoading(false);
        if (Object.keys(res?.errors).length > 0) {
          toast({
            status: "error",
            title: "Error.",
            description: "Invalid File",
          });
          setIsValidateDisabled(false);
          setImportFile();
        } else {
          setIsImportDisabled(false);
          setIsValidateDisabled(true);
          toast({
            status: "success",
            title: "Success.",
            description: "Ready to import",
          });
        }
      });
    } else {
      toast({
        status: "error",
        title: "Error.",
        description: "You have to select file first",
      });
    }
    setOpenLoader(false);
  };

  const importRecommendation = () => {
    setOpenLoader(true);

    const formData = new FormData();
    formData.append("file", importFile);
    formData.append("merchant_id", row._id);
    setIsImportLoading(true);
    const eventSource = new EventSource(baseURL + "/merchant/recommendation", {
      headers: {
        Authorization: getToken(),
      },
      method: "POST",
      payload: formData,
    });

    eventSource.stream();
    onCloseModal();
    toast({
      status: "info",
      title: "info.",
      description: "please wait ",
    });
    setOpenLoader(false);

    eventSource.addEventListener("close", ({ data }) => {
      setIsImportLoading(false);
      onCloseModal();
      toast({
        status: "success",
        title: "Success.",
        description: "Imported Successfully",
      });
      setOpenLoader(false);
    });
  };
  const getImportConfig = async () => {
    const importRes = await getUpdateRecommendationConfig();
    return importRes;
  };

  useEffect(() => {
    if (isOpen) {
      setOpenLoader(true);
      getImportConfig().then((res) => {
        setAcceptedImportFile(['text/csv']);
        setRecommendationSample(res);
        setOpenLoader(false);
      });
    }
  }, [isOpen]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Text onClick={onOpen}>Update recommendation</Text>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update recommendation</ModalHeader>
          {isValidateLoading || isImportLoading ? "" : <ModalCloseButton />}
          <ModalBody>
            <Loader isLoading={openLoader} />

            <FormControl isRequired>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Update recommendation
              </FormLabel>
              <div className="app">
                <div className="parent">
                  <div className="file-upload">
                    <UploadIcon />
                    {!importFile && <p>Maximum file size 5mb</p>}
                    {importFile && <p>{importFile.name}</p>}
                    <input
                      type="file"
                      onChange={(e) => {
                        if (
                          acceptedImportFile.includes(
                            e.target.files[0]?.type
                          ) &&
                          e.target.files[0]?.size < 5000000
                        ) {
                          setIsValidateDisabled(false);
                          setImportFile(e.target.files[0]);
                        } else
                          toast({
                            status: "error",
                            title: "Error.",
                            description: "Invalid File Type",
                          });
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                  </div>
                  <Box
                    color={"blue"}
                    paddingTop={2}
                    fontSize={13}
                    cursor={"pointer"}
                    onClick={() => {
                      openFileInNewTab(recommendationSample)
                    }}
                  >
                    Download Sample
                  </Box>
                </div>
              </div>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              colorScheme="white"
              color={"#3182ce"}
              size="sm"
              onClick={onCloseModal}
              isDisabled={isValidateLoading || isImportLoading}
            >
              Cancel
            </Button>
            <Button
              isDisabled={isValidateDisabled}
              isLoading={isValidateLoading}
              onClick={validate}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Validate
            </Button>

            <Button
              isLoading={isImportLoading}
              isDisabled={isImportDisabled}
              onClick={importRecommendation}
              size="sm"
              color={"white"}
              colorScheme="blue"
            >
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
