import { useToast, Box, Text } from "@chakra-ui/react";
import React from "react";
import { onPrintInvoice } from "../../../api/invoices";
export function PrintInvoice({ row }) {
  const toast = useToast();

  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const printLabel = async () => {
    toast({
      status: "info",
      title: "Downloading File",
      duration: 1000,
    });

    const printInvoiceRes = await onPrintInvoice(row.id);
    if (printInvoiceRes) {
      openFileInNewTab(printInvoiceRes);
      toast({
        status: "success",
        title: "",
        description: "File Downloaded",
      });
    }
  };
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={() => {
          printLabel();
        }}
      >
        <Text>Print Invoice</Text>
      </Box>
    </>
  );
}
