export const columns = [
  {
    type: "link",
    name: "orderNumber",
    label: "Order code",
    searchable: true,
    style: { width: "15rem" },
    stickyLeft: true,
    show: true
  },
  {
    type: "string",
    name: "customer_name",
    label: "Consignee name",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date",
    name: "processed_on",
    label: "Processed on",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "courier",
    label: "Courier",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "customer_phone",
    label: "Consignee phone",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "customer_city",
    label: "Consignee city",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",

    name: "tracking_number",
    label: "Tracking Number",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",

    name: "sub_status",
    label: "Sub status",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "multi_select",
    name: "rejection_reason",
    label: "Rejection reason",
    searchable: true,
    options: [],
    style: { width: "15rem" },
    show: true
  },
  {
    type: "popover",
    name: "support_note",
    label: "Support note",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "number",
    name: "attempts",
    label: "Attempts",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "date",
    name: "updated_at",
    label: "Updated at",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
