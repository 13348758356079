export const columns = [
  {
    type: "string",
    name: "name",
    label: "Name",
    searchable: true,
    style: { width: '16rem' },
    Collapse: true,
    show: true
  },
  {
    type: "string",
    name: "phone",
    label: "Phone",
    searchable: true,
    style: { width: '12rem' },
    show: true
  },
  {
    type: "string",
    name: "backup_phone",
    label: "Backup Phone",
    searchable: false,
    style: { width: '12rem' },
    show: true
  },
  {
    type: "dropdown",
    name: "city",
    label: "City",
    searchable: true,
    options: [],
    style: { width: '12rem' },
    show: false,
    show: false
  }
];
