import React from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import { onForceOrdersSync } from "../../../api/orders";

export function ForceSync({ row, setOpenLoader }) {
  const toast = useToast();

  const onClickForceSync = async () => {
    const body = { id: row.orderId };
    setOpenLoader(true);
    const res = await onForceOrdersSync(body, toast);
    if (res) {
      if (res?.body?.isSynced) {
        toast({
          status: "success",
          title: "",
          description: res?.msg,
        });
      } else {
        toast({
          status: "success",
          title: "",
          description: "No changes",
        });
      }
    }
    setOpenLoader(false);
  };
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={() => {
          onClickForceSync();
        }}
      >
        <Text>Force Sync</Text>
      </Box>
    </>
  );
}
