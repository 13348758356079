import { get, post, getExport } from "./index";

export const getCouriers = async (query, toast) => {
  return post("/courier/listing", { ...query }, undefined, toast);
};

export const getCourierConfig = async (toast) => {
  return getExport("/courier/import-config", undefined, undefined, toast);
};


export const getPricingPlanCodConfig = async (toast) => {
  return getExport('/courier/cod-config', undefined, undefined, toast);
}

export const validateNewCourier = async (body, headers, toast) => {
  return post("/courier/valid", body, headers, toast);
};
