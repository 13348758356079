import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box,
    Text
} from '@chakra-ui/react';
import React from 'react';


export function PricingPlanWeightDetails({ row }) {
    const finalRef = React.useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const onCloseModal = () => {
        onClose();
    }

    return (
        <>
            <Box
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                cursor={"pointer"}
                onClick={onOpen}
                mr={2}
            >
                <Text textDecor={'underline'} fontSize={12} onClick={onOpen}>Show</Text>
            </Box>
            <Modal isCentered finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Weight Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={6} display={'flex'} flexDir={'column'} gap={8}>

                        <Box display={'flex'} flexDir={'row'} >
                            <Box w={'60%'}>
                                <Text fontWeight={'500'}>Extra Weight Threshold : </Text>
                            </Box>
                            <Text>{row.extra_weight_threshold}</Text>
                        </Box>

                        <Box pb={4} display={'flex'} flexDir={'row'}>
                            <Box w={'60%'}>
                                <Text fontWeight={'500'}>Extra Weight Fees :  </Text>
                            </Box>
                            <Text>{row.extra_weight_fees}</Text>
                        </Box>
                    </ModalBody>

                </ModalContent>
            </Modal >

        </>
    )
}