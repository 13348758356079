import { useState, useEffect } from "react";
import {
  useToast,
  Tabs,
  TabList,
  Tab,
  Card,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ListContainer from "components/Wrappers/ListContainer";
import tableActions from "variables/tableActions";
import { columns } from "variables/progressTracking";
import { getCities, getSubStatus, getRejectedReasons } from "api/orders";
import { Loader } from "../../components/Loader/loader";
import {
  getCouriersDropdown,
  getProgressOrdersSum,
  getProgressOrders,
} from "../../api/progress-tracking";

function ProgressTracking() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [ordersSum, setOrdersSum] = useState([]);
  const toast = useToast();
  const [couriers, setCouriers] = useState([]);
  const [progressOrdersData, setProgressOrdersData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [index, setIndex] = useState(0);
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
    sortAttribute: "orderNumber",
    sortDirection: "ASC",
  });
  const [openLoader, setOpenLoader] = useState(false);

  const getCouriersDropdownData = async () => {
    setIsLoading(true);
    const res = await getCouriersDropdown({}, toast);
    if (res) setCouriers(res.couriers);
    setIsLoading(false);
  };

  const getProgressOrderSumData = async (body) => {
    const res = await getProgressOrdersSum(body, toast);
    return res;
  };

  const getDropDowns = async () => {
    setOpenLoader(true);
    const citiesRes = await getCities(toast);
    const subStatusRes = await getSubStatus(toast);
    const rejectedReasonRes = await getRejectedReasons(toast);
    const cityCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "customer_city"
    );

    const subStatusCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "sub_status"
    );
    const rejectionReasonCol = columns.find(
      (col) => col.type === "multi_select" && col.name === "rejection_reason"
    );
    cityCol.options = citiesRes.govs;
    subStatusCol.options = subStatusRes.subStatuses;
    rejectionReasonCol.options = rejectedReasonRes.rejectionReasons;
    setOpenLoader(false);
  };

  const handleChange = (selectedOptions) => {
    setOpenLoader(true);
    setSelectedValues(selectedOptions);
    getProgressOrderSumData({
      filters: { courier_ids: selectedOptions },
    }).then((res) => {
      setOrdersSum(res);
      setIndex(0);
      const selectedCourierIds = selectedOptions.map((rec) => rec.value);
      const progressQuery = {
        ...filterQuery,
        shipment_age: "1",
        filters: { courier_ids: selectedCourierIds },
      };
      fetchData({ ...progressQuery });
    });
  };

  const fetchData = async (query) => {
    setIsLoading(true);
    setOpenLoader(true);

    const res = await getProgressOrders({ ...query }, toast);
    if (res) {
      setProgressOrdersData(res.orders);
      setTotalCount(res.orderCount);
    }
    setOpenLoader(false);
    setIsLoading(false);
  };

  const fetchDataFilterData = async (query) => {
    setOpenLoader(true);
    setIsLoading(true);
    const res = await getProgressOrders(
      {
        ...query,
        shipment_age: ordersSum[index]?.name,
        filters: {
          ...query.filters,
          courier_ids: selectedValues.map((rec) => rec.value),
        },
      },
      toast
    );
    if (res) {
      setProgressOrdersData(res.orders);
      setTotalCount(res.orderCount);
    }
    setIsLoading(false);
    setOpenLoader(false);
  };

  const onClickTab = (record) => {
    setIndex(record.name - 1);
    const selectedCourierIds = selectedValues.map((rec) => rec.value);
    const progressQuery = {
      ...filterQuery,
      shipment_age: record.name,
      filters: { courier_ids: selectedCourierIds, ...filterQuery.filters },
    };
    fetchData({ ...progressQuery });
  };

  useEffect(() => {
    columns.find((col) => col.type === "link")["onClickLink"] = (row) => {
      window.open("/admin/orders/" + row.orderId + "/details", "_blank");
    };
    getCouriersDropdownData();
    getDropDowns();
    if (filterQuery.page && ordersSum && ordersSum[index] && ordersSum[index].name) {
      fetchDataFilterData(filterQuery);
    }
  }, [filterQuery.page]);

  return (
    <>
      <Loader isLoading={openLoader} />

      <Card marginTop={"2rem"} padding={6} borderRadius={40} height={"52rem"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Text fontSize={"1rem"} fontWeight={700}>
              Progress Tabs
            </Text>
          </Box>
        </Box>
        <Flex pt={12} direction={"column"} w={"30%"} gap={2}>
          <Text fontSize={"13px"} fontWeight={600} lineHeight={"20px"}>
            Courier
          </Text>
          <div w={"50%"}>
            <Select
              placeholder=""
              isMulti
              value={selectedValues}
              onChange={(selectedOptions) => handleChange(selectedOptions)}
              options={couriers.map((option) => {
                return { value: option.id, label: option.name };
              })}
            />
          </div>
        </Flex>
        {selectedValues.length > 0 && (
          <Box>
            <Tabs index={index} pt={6}>
              <TabList>
                {ordersSum.map((record, index) => {
                  return (
                    <>
                      <Tab
                        onClick={() => {
                          onClickTab(record);
                        }}
                        fontSize={"0.875rem"}
                        fontWeight={500}
                        lineHeight={"1.5rem"}
                        letterSpacing={"0.03rem"}
                        width={"15rem"}
                        key={index}
                      >
                        {record.label} ({record.count})
                      </Tab>
                    </>
                  );
                })}
              </TabList>
            </Tabs>
            <Box p={4}>
              <ListContainer
                title="Progress Orders"
                columns={columns}
                data={progressOrdersData}
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
                totalCount={totalCount}
                tableActions={tableActions["ProgressOrders"]}
                hasPagination={true}
                fetchData={fetchDataFilterData}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        )}
      </Card>
    </>
  );
}

export default ProgressTracking;
