const globalApiWrapper = (api) => {
  return async (...args) => {
    const toast = args[args.length - 1];
    try {
      return await api(...args);
    } catch (e) {
      // for error codes 401 and 403
      if (e.response.status === 401 || e.response.status === 403) {
        toast({
          position: "top-right",
          status: "error",
          isClosable: true,
          title: "Error.",
          description: "Invalid Access",
          duration: 9000,
        });

        localStorage.removeItem("tokenKey");
        localStorage.removeItem("role");

        //route to login page
        window.location.href = "/#/auth/signin";
      } else if (e.response.status === 500) {
        toast({
          position: "top-right",
          status: "error",
          isClosable: true,
          title: "Error fetching data.",
          description: "Please contact your admin",
          duration: 9000,
        });
      } else if (e.response.status === 400) {
        toast({
          position: "top-right",
          status: "error",
          isClosable: true,
          title: "Error.",
          description: e.response.data.msg,
          duration: 9000,
        });
      } else if (e.response.status === 408) {
        toast({
          position: "top-right",
          status: "error",
          isClosable: true,
          title: "Error.",
          description: e.response.data.msg,
          duration: 9000,
        });
        return;
      } else {
        throw e;
      }
    }
  };
};

export default globalApiWrapper;
