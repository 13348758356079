import React from "react";

import { Tbody, Tr, Td, Flex, useColorModeValue } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";
const TableSkeleton = ({columnsCount}) => {
  const rows = Array(25).fill(null);
  const columns = Array(columnsCount).fill(null);
  const borderColor = useColorModeValue("gray.200", "gray.600");
  return (
    <Tbody>
      {rows.map((_, index) => {
        return (
          <Tr key={index}>
            {columns.map((_, index) => (
              <Td p={2} key={index} borderColor={borderColor}>
                <Flex
                  width={"15rem"}
                  key={index}
                  align="center"
                  flexWrap="nowrap"
                  justifyContent={"center"}
                >
                  <Skeleton width="90%" height="27px" color="lightgrey" />
                </Flex>
              </Td>
            ))}
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default TableSkeleton;
