import { get, post, put, getExport, download, stream } from "./index";

export const getMerchants = async (query, toast) => {
  return post("/merchant/listing", { ...query }, undefined, toast);
};

export const getMerchantsExport = async (query, toast) => {
  return getExport("/merchant/listing/export", undefined, undefined, toast);
};

export const onCreateInvoice = async (body, toast) => {
  return post("/invoice", body, undefined, toast);
};

export const onUpdateBalance = async (body, isDeduct, toast) => {
  return post(
    isDeduct ? "/merchant/deduct-balance" : "/merchant/add-balance",
    body,
    undefined,
    toast
  );
};

export const getIndustries = async (toast) => {
  return get("/merchant/industries", undefined, undefined, toast);
};

export const getCustomization = async (toast) => {
  return get("/merchant/customization", undefined, undefined, toast);
};

export const onAddMerchant = async (body, headers, toast) => {
  return post("/merchant", { ...body }, headers, toast);
};

export const getAreaMappingConfig = async (toast) => {
  return get("/merchant/area-mapping/config", undefined, undefined, toast);
};

export const validateMerchantAreaMapping = async (body, headers, toast) => {
  return post("/merchant/area-mapping/valid", body, headers, toast);
};

export const getMerchantPermissions = async (toast) => {
  return get("/lookups/merchant-permissions", undefined, undefined, toast);
};

export const updateMerchant = async (id, body, toast) => {
  return put("/merchant" + `/${id}`, { ...body }, undefined, toast);
};

export const getPricingPlans = async (toast) => {
  return post(
    "/plan-pricing/listing",
    { filters: { status: "active" } },
    undefined,
    toast
  );
};

export const assignMerchantToPlan = async (
  id,
  payload,
  toast
) => {
  const formData = new FormData();
  formData.append("merchant_id", payload.merchant_id);  
  return stream(
    "/plan-pricing/" + id + "/merchant",
    toast,
    { method: 'PATCH', payload: formData }
  );
};

export const getUpdateRecommendationConfig = async (toast) => {
  return getExport("/merchant/import-config", undefined, undefined, toast, false);
};

export const validateRecommendationFile = async (body, toast) => {
  return post("/merchant/valid", body, undefined, toast);
};

export const downloadRecommendationFileSample = async (toast) => {
  return download(
    "/merchant/import-config",
    "courier-recommendation-file.csv",
    "text/csv",
    undefined,
    toast
  );
};

export const getCities = async (toast) => {
  return get("/lookups/governorates/v2", undefined, undefined, toast);
};

export const getCouriersLookups = async (toast) => {
  return get("/lookups/couriers?country=EG", undefined, undefined, toast);
};

export const getOrderTypes = async (toast) => {
  return get("/lookups/order/types", undefined, undefined, toast);
};

export const updateMerchantFees = async (body, id, toast) => {
  return put("/merchant/" + id + "/fees", body, undefined, toast);
};

export const getMerchantPlanDetails = async (id, query, toast) => {
  return post("/merchant/" + id + "/plan-details", query, undefined, toast);
};
