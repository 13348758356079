import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Collapse, Center, Flex, Card, useToast } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Pagination from 'components/Wrappers/Pagination';
import { columns } from "variables/customersData";
import Filter from 'components/Filters/Filter';
import { getCutomers } from 'api/customers';
import { getCutomerMerchant } from 'api/customers';
import { Loader } from 'components/Loader/loader';
import { getCities } from 'api/orders';

function Customers() {
  const toast = useToast();

  const [openRows, setOpenRows] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
  });
  const [customersData, setCustomersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getCutomers(query, toast);
    if (res) {
      setCustomersData(res.customers);
      setTotalCount(res.totalCustomers);
      setOpenRows({})
    }
    setIsLoading(false);
  }
  const fetchDetailsData = async (record) => {
    if (!record['couriers']) {
      setIsLoading(true);
      const res = await getCutomerMerchant(record.id, toast);
      if (res) {
        const updatedCustomersData = customersData.map(rec =>
          rec.id === record.id ? { ...rec, couriers: res.ordersPerMerchant } : rec
        );
        setCustomersData(updatedCustomersData);
      }
      setIsLoading(false);
    }
  }

  const getDropDowns = async () => {
    const citiesRes = await getCities(toast);
    const cityCol = columns.find(
      (col) => col.type === "dropdown" && col.name === "city"
    );
    cityCol.options = citiesRes.govs;
  };


  const toggleRow = (index, record) => {
    setOpenRows(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
    fetchDetailsData(record);
  };


  useEffect(() => {
    fetchData(filterQuery);
    getDropDowns();
  }, [filterQuery.page]);

  return (
    <>
      <Loader isLoading={isLoading} />

      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <CardHeader bg={"white"}>
          <Flex
            paddingLeft={2}
            paddingBottom={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex flexDir={"column"}>
              <Text fontSize="xl" fontWeight="bold">
                Customers
              </Text>
              <Text fontSize="xl" fontWeight="bold">
                {totalCount}
              </Text>
            </Flex>
            <Box alignItems={"center"} marginRight={6} display={"flex"} gap={4}>
              {columns.some((col) => col.searchable) && (
                <Filter
                  columns={columns.filter(col => col.searchable)}
                  filterQuery={filterQuery}
                  setFilterQuery={setFilterQuery}
                  fetchData={fetchData}
                />
              )}
            </Box>
          </Flex>
          <Pagination
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
            totalCount={totalCount}
          />
        </CardHeader>
        <CardBody>
          <Box background={'white'} m={6}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  {columns?.filter(col => col.show)?.map((column, index) => {
                    return (
                      <Th
                        width={column.style.width}
                        bg={"white"}
                        fontSize={"13px"}
                        color={"rgb(127, 125, 128)"}
                        fontStyle={"normal"}
                        textAlign={"center"}
                        key={index}
                        background={"rgb(251, 251, 251)"}
                        height={"44px"}
                      >
                        {column.label}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {customersData?.map((record, index) => (
                  <React.Fragment key={record.id}>
                    <Tr>
                      {columns?.filter(col => col.show)?.map((column, colIndex) => {
                        return (
                          <Td
                            fontSize={"13px"}
                            onClick={() => {
                              if (column.Collapse)
                                toggleRow(index, record)
                            }}
                            p={2}
                            bg="white"
                            color={column.Collapse ? '#319795' : ''}
                            cursor={column.Collapse ? 'pointer' : ''}
                            key={colIndex}
                          >
                            <Flex
                              align="center"
                              flexWrap="nowrap"
                              justifyContent={"center"}
                            >
                              {record[column.name]}
                            </Flex>
                          </Td>
                        );
                      })}
                    </Tr>

                    <Collapse in={openRows[index]}>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th
                              bg={"white"}
                              fontSize={"13px"}
                              color={"rgb(127, 125, 128)"}
                              fontStyle={"normal"}
                              textAlign={"center"}
                              background={"rgb(251, 251, 251)"}
                              height={"44px"}
                            >
                              Merchant  Name
                            </Th>
                            <Th
                              bg={"white"}
                              fontSize={"13px"}
                              color={"rgb(127, 125, 128)"}
                              fontStyle={"normal"}
                              textAlign={"center"}
                              background={"rgb(251, 251, 251)"}
                              height={"44px"}
                            >
                              Orders Count
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {record?.couriers?.map((item, itemIndex) => (
                            <Tr key={itemIndex}>
                              <Td>
                                <Center fontSize={"0.8125rem"}
                                  fontWeight={"500"}
                                  letterSpacing={"0.03em"}
                                  pos="relative"
                                  bgColor="inherit"
                                  w="100%"
                                  h="100%">
                                  {item.merchant}
                                </Center>
                              </Td>
                              <Td>
                                <Center fontSize={"0.8125rem"}
                                  fontWeight={"500"}
                                  letterSpacing={"0.03em"}
                                  pos="relative"
                                  bgColor="inherit"
                                  w="100%"
                                  h="100%">
                                  {item.orders}
                                </Center>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Collapse>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </Box>
        </CardBody >
      </Card >
    </>
  );
}

export default Customers;
