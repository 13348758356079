import { SSE } from "sse.js";
import { download, post } from "./index";

export const getLeads = async (query, toast) => {
  return post("/lead/listing", { ...query }, undefined, toast);
};

export const convertToMerchant = async (
  id,
  {
    password,
    prefix,
    order_creation_behavior,
    hotline,
    hide_pickup_location,
    permissions,
    plan,
    ["courier-recommendation"]: courierRecommendation,
    ["area-mapping"]: areaMapping,
  },
  toast
) => {
  const { REACT_APP_ADMIN_API_URL: baseURL } = process.env;
  const formData = new FormData();
  formData.append("password", password);
  formData.append("prefix", prefix);
  formData.append("order_creation_behavior", order_creation_behavior);
  formData.append("hotline", hotline);
  formData.append("hide_pickup_location", hide_pickup_location);
  if (plan) formData.append("plan", plan);
  if (courierRecommendation && courierRecommendation !== "")
    formData.append("courier-recommendation", courierRecommendation);
  if (areaMapping && areaMapping !== "")
    formData.append("area-mapping", areaMapping);
  permissions.forEach((permission, index) => {
    formData.append(`permissions[${index}][name]`, permission.name);
    formData.append(`permissions[${index}][status]`, permission.status);
  });
  const eventSource = new SSE(`${baseURL}/lead/${id}/convert`, {
    headers: {
      Authorization: localStorage.getItem("tokenKey"),
    },
    method: "POST",
    payload: formData,
  });

  return new Promise((resolve, reject) => {
    eventSource.stream();
    eventSource.addEventListener("error", (eventObject) => {
      toast({
        status: "error",
        title: "Error.",
        description: eventObject.data,
      });
      reject(new Error(eventObject.data));
    });

    eventSource.addEventListener("message", (eventObject) => {
      toast({
        status: "success",
        title: "Success.",
        description: eventObject.data,
      });
      resolve(eventObject);
    });
  });
};

export const exportLeads = (filters, toast, cp, errorCallback) => {
  return download("/lead/listing/export", "exported-leads.csv", "text/csv");
};
