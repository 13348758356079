import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Input,
  useDisclosure,
  Box,
  Select,
  Flex,
  Radio,
  RadioGroup,
  Stack
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getCities, getCouriersLookups, getOrderTypes, updateMerchantFees } from '../../../api/merchants';
import { Loader } from "components/Loader/loader";
export function UpdateMerchantFees({ row, fetchData, filterQuery }) {
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openLoader, setOpenLoader] = useState(false);
  const [cities, setCities] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [couriers, setCouriers] = useState([]);

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    unregister,
  } = useForm({
    defaultValues: {
      city: "",
      courier: "",
      orderType: "delivery",
      feeValue: "",
      changeType: ""
    },
  });
  const { errors, isDirty } = formState;
  const form = watch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const onError = (errors) => console.log(errors);

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const getDropDowns = async () => {
    setOpenLoader(true);
    const citiesRes = await getCities(toast);
    const couriersRes = await getCouriersLookups(toast);
    const orderTypesRes = await getOrderTypes(toast);
    setCities(citiesRes.govs);
    setCouriers(couriersRes.couriers);
    setOrderTypes(orderTypesRes.types);
    setOpenLoader(false);
  };


  const handelSubmit = async (data) => {
    const body = {
      fee_value: +data.feeValue, change_type: data.changeType,
      filters: { city: [data.city], courier: [data.courier], order_type: [data.orderType] }
    };
    setIsLoading(true);
    updateMerchantFees(body, row._id, toast).then(() => {
      onCloseModal();
      toast({ status: 'success', title: 'Updated' });
      fetchData(filterQuery);
      setIsLoading(false);
    })
  };

  useEffect(() => {
    if (isOpen) {
      getDropDowns();
      register("city", {
        required: "city is required",
      });
      register("courier", {
        required: "courier is required",
      });
      register("orderType", {
        required: "order Type is required",
      });
      register("feeValue", {
        required: "fee Value is required",
      });
      register("changeType", {
        required: "change Type is required",
      });
    }
  }, [isOpen]);
  return (
    <>
      <Loader isLoading={openLoader} />

      <Box
        display={"flex"}
        alignItems={"center"}
        cursor={"pointer"}
        justifyContent={"center"}
        onClick={onOpen}
      >
        <Button variant="unstyled" onClick={onOpen}>
          <Flex fontSize={12} gap={2}>
            Update merchant fees
          </Flex>
        </Button>
      </Box>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update merchant fees</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!!errors.city} isRequired>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                City
              </FormLabel>
              <Select
                onChange={(e) => setValue("city", e.target.value)}
                value={form["city"]}
                placeholder="Select city"
              >
                {cities.map((city, i) => <option key={i} value={city.value}>{city.label}</option>)}
              </Select>
              <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.courier} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                courier
              </FormLabel>
              <Select
                onChange={(e) => setValue("courier", e.target.value)}
                value={form["courier"]}
                placeholder="Select Courier"
              >
                {couriers.map((courier, i) => <option key={i} value={courier.value}>{courier.label}</option>)}
              </Select>
              <FormErrorMessage>{errors?.courier?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Order type
              </FormLabel>
              <Select
                onChange={(e) => setValue("orderType", e.target.value)}
                value={form["orderType"]}
                placeholder="Select order type"
              >
                {orderTypes.map((type, i) => <option key={i} value={type.value}>{type.label}</option>)}
              </Select>
              <FormErrorMessage>{errors?.orderType?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.changeType} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Change type
              </FormLabel>
              <RadioGroup onChange={(e) => setValue("changeType", e)} >
                <Stack direction='row'>
                  <Radio value='fixed'>Fixed</Radio>
                  <Radio value='percentage'>Percentage</Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>{errors?.changeType?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.feeValue} isRequired mt={6}>
              <FormLabel fontSize={12} fontWeight={"semibold"}>
                Fee value
              </FormLabel>
              <Input
                placeholder="Enter fees value"
                type="number"
                value={form["feeValue"]}
                onChange={(e) => {
                  setValue("feeValue", e.target.value);
                }}
              />
              <FormErrorMessage>{errors?.feeValue?.message}</FormErrorMessage>
            </FormControl>

          </ModalBody>
          <ModalFooter display={"flex"} gap={6}>
            <Button
              size="sm"
              color={"white"}
              colorScheme="blue"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color={"white"}
              colorScheme="blue"
              isLoading={isLoading}
              onClick={handleSubmit(handelSubmit, onError)}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
} 