import React from "react";
import {
  Box,
  Text,
  useToast,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { onCompleteOrder } from "../../../api/orders";

export function CompleteOrder({ row, setOpenLoader }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const onClickCompleteOrder = async () => {
    setOpenLoader(true);
    const body = { order_id: row.orderId };
    const res = await onCompleteOrder(body, toast);
    if (res) {
      toast({
        status: "success",
        title: "Order Completed.",
      });
    }
    onClose();
    setOpenLoader(false);
  };
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        cursor={"pointer"}
        onClick={() => {
          onOpen();
        }}
      >
        <Text>Complete Order</Text>
      </Box>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Complete Order</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to complete this order ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button onClick={onClickCompleteOrder} colorScheme="red" ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
