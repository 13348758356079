import { useState, useEffect } from "react";
import {
  useToast,
  Card,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  Flex,
  Text,
  Input,
  Box,
  Button,
  AlertIcon,
  Alert,
  Textarea,
} from "@chakra-ui/react";
import ListContainer from "components/Wrappers/ListContainer";
import tableActions from "variables/tableActions";
import { columns } from "variables/pricingPlansData";
import { Loader } from "components/Loader/loader";

import { LuAlertCircle, LuCheck, LuSend, LuX } from "react-icons/lu";
import { getPricingPlansData } from "api/pricing-plans";
import { sendCommercialCampaigns } from "api/commercial-campaigns";

function CommercialCampaigns() {
  const toast = useToast();
  const [text, setText] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState();
  const [error, setError] = useState();
  const [toBeSendToPhones, setToBeSendToPhones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [failedNumbers, setFailedNumbers] = useState([]);
  const [isSuccess, setSuccess] = useState(false);
  const [sentNumbers, setSentNumbers] = useState([]);
  const submit = async () => {
    setPhoneError();
    setError();
    setIsLoading(true);
    setSuccess(false);
    try {
      if (text.trim().length === 0) {
        throw new Error("Message text cannot be empty!");
      } else if (toBeSendToPhones.length === 0) {
        throw new Error("Please add at-least 1 phone number!");
      }
      const sendTo = toBeSendToPhones.filter((x) => !sentNumbers.includes(x));
      const response = await sendCommercialCampaigns(text, sendTo, toast);
      console.log(response);
      if (!response) {
        setFailedNumbers(sendTo);
        throw new Error("Failed to send the message to all of the recipients");
      } else if (response.failed.length > 0) {
        setSentNumbers((p) => [
          ...p,
          ...toBeSendToPhones.filter((x) => !response.failed.includes(x)),
        ]);
        setFailedNumbers(response.failed);
        setToBeSendToPhones(response.failed);
        throw new Error(
          `Failed to send the message to ${response.failed
            .map((phone) => `"${phone}"`)
            .join(", ")}`
        );
      }
      setToBeSendToPhones([]);
      setSentNumbers((p) => [...p, ...toBeSendToPhones]);
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <Flex direction="column" gap={2}>
          <Flex
            paddingLeft={2}
            paddingBottom={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex flexDir={"column"}>
              <Text fontSize="xl" fontWeight="bold">
                Commercial Campaigns
              </Text>
            </Flex>
          </Flex>
          <Text fontWeight="semibold" color="GrayText">
            Recipients
          </Text>
          <Card variant="outline">
            <Table size="sm">
              <Tbody>
                {toBeSendToPhones.length === 0 && sentNumbers.length === 0 && (
                  <Tr>
                    <Td align="center" textAlign="center" fontStyle="italic">
                      No recipients added yet
                    </Td>
                  </Tr>
                )}

                {toBeSendToPhones.map((phone, i) => {
                  const isFailed = failedNumbers.includes(phone);
                  return (
                    <Tr
                      key={phone}
                      sx={{
                        background: isFailed ? "red.100" : undefined,
                        _hover: {
                          background: isFailed ? "red.100" : "gray.50",
                        },
                      }}
                    >
                      <Td w={0}>{phone}</Td>
                      <Td w={0} color="red.400">
                        {isFailed && <LuAlertCircle color="inherit" />}
                      </Td>
                      <Td align="center" textAlign="end" fontStyle="italic">
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            setToBeSendToPhones((phones) => {
                              const newPhones = [...phones];
                              newPhones.splice(i, 1);
                              return newPhones;
                            });
                          }}
                        >
                          <LuX />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
                {sentNumbers.map((phone, i) => {
                  return (
                    <Tr
                      key={phone}
                      sx={{
                        background: "gray.50",
                      }}
                    >
                      <Td w={0}>{phone}</Td>
                      <Td w={0}></Td>
                      <Td align="center" textAlign="end" fontStyle="italic">
                        <Flex
                          height={8}
                          width={"38px"}
                          float="right"
                          color="green.500"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <LuCheck color="inherit" />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Card>

          <Flex
            as="form"
            direction="column"
            onSubmit={(ev) => {
              ev.preventDefault();
              if (!phone.match(/^[0-9]{10,}$/)) {
                setPhoneError("Invalid phone number!");
              } else if (toBeSendToPhones.includes(phone)) {
                setPhoneError("Phone already exist!");
              } else if (sentNumbers.includes(phone)) {
                setPhoneError("You already sent the message to this phone!");
              } else {
                setToBeSendToPhones((p) => [...p, phone]);
                setSuccess(false);
                setPhone("");
              }
            }}
          >
            <Flex gap={2}>
              <Input
                placeholder="Enter phone number"
                value={phone}
                onChange={(ev) => {
                  setPhone(ev.target.value);
                  setPhoneError();
                }}
                isInvalid={!!phoneError}
                // value={form["weight"]}
                // onChange={(e) => setValue("weight", e.target.value)}
              />
              <Button type="submit">Add</Button>
            </Flex>

            {phoneError ? (
              <Text fontSize="small" color="red.500">
                {phoneError}
              </Text>
            ) : (
              <Text fontSize="small" color="gray.400">
                Please note you have to enter the number in the international
                format, for example an Egyptian number would look like this{" "}
                <u>20</u>
                123456789
              </Text>
            )}
          </Flex>

          <Text fontWeight="semibold" color="GrayText">
            Content
          </Text>
          <Textarea
            placeholder="Enter your message here..."
            value={text}
            onChange={(ev) => setText(ev.target.value)}
            isDisabled={sentNumbers.length > 0}
          />
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {isSuccess && (
            <Alert status="success">
              <AlertIcon />
              All messages sent successfully!
            </Alert>
          )}
          <Flex justifyContent="end">
            <Button
              colorScheme="blue"
              onClick={submit}
              rightIcon={<LuSend />}
              isLoading={isLoading}
              isDisabled={toBeSendToPhones.length === 0}
            >
              Send
            </Button>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}

export default CommercialCampaigns;
