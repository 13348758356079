import { get, post } from "./index";

export const getCouriersDropdown = async (query, toast) => {
  return post("/courier/listing", { ...query }, undefined, toast);
};

export const getProgressOrdersSum = async (body, toast) => {
  return post("/order/progress/sum", { ...body }, undefined, toast);
};

export const getProgressOrders = async (body, toast) => {
  return post("/order/listing/processing", { ...body }, undefined, toast);
};
