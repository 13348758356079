export const columns = [
  {
    type: "string",
    name: "_id",
    label: "Id",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "name",
    label: "Name",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "prefix",
    label: "Prefix",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "phone",
    label: "Phone",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "industry",
    label: "Industry",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "email",
    label: "Email",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "brand",
    label: "Brand",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "avg_monthly_orders",
    label: "Avg Monthly Orders",
    style: { width: "15rem" },
    searchable: false,
    show: true
  }, 
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
