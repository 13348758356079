import React, { useState, useEffect, useRef } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    InputGroup,
    Input,
    Box,
    Text,
    useDisclosure,
    FormErrorMessage,
    useToast,
    Center,
    Icon
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { AddIcon } from "@chakra-ui/icons";

import { getPricingPlanCodConfig, getPricingPlanCourierConfig, getCountries, getCouriersByCountry, validateAddPricingPlan, addPricingPlan } from '../../../api/pricing-plans'
import { useForm } from "react-hook-form";

export function AddPricingPlan({ row, setOpenLoader, fetchData, filterQuery }) {
    const UploadIcon = (props) => (
        <Icon width="24px" height="34px" viewBox="0 0 28 36" fill="none" {...props}>
            <path
                d="M23.1 14H25.7C26.0448 14 26.3754 14.1446 26.6192 14.402C26.863 14.6594 27 15.0085 27 15.3725V33.6275C27 33.9915 26.863 34.3406 26.6192 34.598C26.3754 34.8554 26.0448 35 25.7 35H2.3C1.95522 35 1.62456 34.8554 1.38076 34.598C1.13696 34.3406 1 33.9915 1 33.6275V15.3725C1 15.0085 1.13696 14.6594 1.38076 14.402C1.62456 14.1446 1.95522 14 2.3 14H4.9"
                stroke="#7F7D80"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 25.6666V4.66663"
                stroke="#7F7D80"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3335 9.33329L14.0002 4.66663L9.66683 9.33329"
                stroke="#7F7D80"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Icon>
    );
    const [couriers, setCouriers] = useState([]);
    const finalRef = React.useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const [isAddPricingPlanLoading, setIsPricingPlanLoading] = useState(false);
    const [isValidateLoading, setIsValidateLoading] = useState(false);
    const [isImportLoading, setIsImportLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('')
    const [description, setDescription] = useState("");
    const [pricingPlanName, setPricingPlanName] = useState("");
    const [codFile, setCodFile] = useState();
    const [isCourierLoading, setIsCourierLoading] = useState(false);
    const [selectedCouriers, setSelectedCouriers] = useState([]);
    const [isImportDisabled, setIsImportDisabled] = useState(true);
    const [acceptedCodFile, setAcceptedCodFiles] = useState();
    const toast = useToast();
    const [codSample, setCodSample] = useState();
    const [pricingPlanSample, setPricingPlanSample] = useState();
    const [acceptedCourierFiles, setAcceptedCourierFiles] = useState();
    const [uploadedCouriers, setUploadedCouriers] = useState([]);
    const [formValue, setFormValue] = useState({})
    const onCloseModal = () => {
        setUploadedCouriers([]);
        setPricingPlanName('');
        setDescription('');
        setSelectedCountry('');
        setSelectedCouriers([]);
        setIsImportDisabled(true);
        setCodFile();
        onClose();
        reset();
    }

    const getConfig = () => {
        getPricingPlanCodConfig(toast).then(res => {
            setAcceptedCodFiles(['text/csv'])
            setCodSample(res);
        })
    }

    const getPricingPlanSample = () => {
        getPricingPlanCourierConfig(toast).then(res => {
            setAcceptedCourierFiles(['text/csv'])
            setPricingPlanSample(res);
        })
    }

    const getCountriesLookup = () => {
        getCountries(toast).then(res => {
            setCountries(res.countries);
        })
    }


    const getCourierByCountry = (country) => {
        setIsCourierLoading(true);
        getCouriersByCountry(country, toast).then(res => {
            const tempCouriers = res.couriers.map(record => {
                return {
                    file: {}, ...record
                }
            })
            setIsCourierLoading(false);

            setCouriers(tempCouriers);
        })
    }

    const getToken = () => {
        return localStorage?.getItem("tokenKey");
    };

    const openFileInNewTab = (response) => {
        const file = new Blob([response.data], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    };

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        watch,
        reset,
        unregister,
    } = useForm({
        defaultValues: {
            pricingPlanName: "",
            description: "",
            selectedCountry: '',
        },
    });
    const { errors, isDirty } = formState;
    const onError = (errors) => console.log(errors);
    const form = watch();


    const handelValidate = (data) => {
        setFormValue({ ...data });
        if (!codFile) {
            toast({
                status: 'error',
                title: 'Error.',
                description: 'Please upload the cod file'
            })
        } else if (selectedCouriers.length === 0) {
            toast({
                status: 'error',
                title: 'Error.',
                description: 'You have to select at least 1 courier'
            })
        } else if (uploadedCouriers.length === 0) {
            toast({
                status: 'error',
                title: 'Error.',
                description: 'You have to upload at least 1 pricing plan'
            })
        } else {
            setIsValidateLoading(true);

            let body = {
                name: data.pricingPlanName,
                description: data.description,
                'pricing-plan-cod': codFile,
                countries: `['${selectedCountry.value}']`
            }
            uploadedCouriers.forEach(rec => {
                body[rec.name] = rec.file;
            })

            validateAddPricingPlan(body, toast, { "Content-Type": "multipart/form-data", Authorization: getToken() }).then(res => {
                setIsValidateLoading(false);
                if (res?.body?.errors && Object.keys(res.body.errors).length > 0) {
                    if (res.body.errors.CODFileErrors) {
                        toast({
                            status: 'error',
                            title: 'Invalid Cod File.',
                            description: res.body.errors.CODFileErrors
                        })
                    }

                    if (res.body.errors.planErrors) {
                        toast({
                            status: 'error',
                            title: 'Error.',
                            description: res.body.errors.planErrors
                        })
                    }

                    if (res.body.errors.courierFilesErrors) {
                        Object.keys(res.body.errors.courierFilesErrors).forEach(key => {
                            console.log(key);
                            let tempErrorCourier = uploadedCouriers.find(record => record.name === key)
                            console.log(tempErrorCourier);
                            tempErrorCourier['hasError'] = true;
                            tempErrorCourier['error'] = res.body.errors.courierFilesErrors[key]; setUploadedCouriers([...uploadedCouriers, ...[tempErrorCourier]]);
                            toast({
                                status: 'error',
                                title: 'Error.',
                                description: 'Please Check Courier Files'
                            })
                        })
                    }
                } else {
                    setIsImportDisabled(false);
                    toast({
                        status: 'success',
                        title: 'Success.',
                        description: 'Ready to import'
                    })
                }
            })
            // post('/plan-pricing/valid', body, {
            //     "Content-Type": "multipart/form-data",
            //     Authorization: getToken(),
            // }).then(res => {

            // }).catch(() => {
            //     setIsValidateLoading(false);
            //     toast({
            //         status: 'error',
            //         title: 'Error.',
            //         description: 'please contact your admin'
            //     })
            // })
        }
    }

    const handelSubmit = (data) => {
        handelValidate(data);
    }

    useEffect(() => {
        if (isOpen) {
            getConfig();
            getPricingPlanSample();
            getCountriesLookup();
            register("pricingPlanName", {
                required: "Name is required",
                validate: (value) =>
                    value.length > 1 && value.length < 150 ? true : "Invalid name",
            });
            register("description", {
                required: "description is required",
                validate: (value) =>
                    value.length > 1 && value.length < 150 ? true : "Invalid name",
            });
            register("selectedCountry", {
                required: "selectedCountry is required",
            });
        }
    }, [isOpen]);


    return <>
        <Box
            display={"flex"}
            alignItems={"center"}
            cursor={"pointer"}
            onClick={onOpen}
        >
            <Button onClick={onOpen}>
                <AddIcon boxSize={4} />
            </Button>

        </Box>

        <Modal closeOnOverlayClick={false} closeOnEsc={false} finalFocusRef={finalRef} size={'5xl'} isOpen={isOpen} onClose={onCloseModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Pricing Plan</ModalHeader>
                {isValidateLoading || isImportLoading ? '' : <ModalCloseButton />}
                <ModalBody display={'flex'} flexDir={'row'} gap={6}>
                    <Box display={'flex'} flexDir={'column'} gap={6}>
                        <FormControl isRequired isInvalid={!!errors.pricingPlanName}>
                            <FormLabel >Name</FormLabel>
                            <Input
                                placeholder="Enter pricing plan name"
                                type="text"
                                value={form["pricingPlanName"]}
                                onChange={(e) => {
                                    setValue('pricingPlanName', e.target.value)
                                    setIsImportDisabled(true);
                                    setPricingPlanName(e.target.value)
                                }}
                            />
                            <FormErrorMessage>{errors?.pricingPlanName?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl isRequired isInvalid={!!errors.description} >
                            <FormLabel >Description</FormLabel>
                            <Input
                                placeholder="Enter Description"
                                type="text"
                                value={form["description"]}
                                onChange={(e) => {
                                    setValue('description', e.target.value)
                                    setIsImportDisabled(true);
                                }}
                            />
                            <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl
                            isRequired >
                            <FormLabel >Cod File</FormLabel>

                            <Box display={'flex'} cursor={'pointer'} flexDir={'row'} gap={8}>
                                <Box color={'blue'} fontSize={13} cursor={'pointer'} onClick={() => {
                                    console.log('here');


                                    openFileInNewTab(codSample)
                                }}>Download COD Sample</Box>
                            </Box>

                            <Center
                                px="10"
                                py="6"
                                borderRadius={10}
                                h="100%"
                                cursor="pointer"
                                border="1px"
                                borderStyle="dashed"
                                borderColor="gray.200"
                                width={'25rem'}
                                padding={'1rem'}
                                onClick={() => {
                                    setIsImportDisabled(true);
                                    inputRef.current?.click();
                                }}>
                                <Input
                                    ref={inputRef}
                                    type="file"
                                    aria-hidden="true"
                                    w={'100%'}
                                    multiple={false}
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        if (acceptedCodFile.includes(e.target.files[0]?.type) && e.target.files[0]?.size < 5000000) {
                                            setCodFile(e.target.files[0]);
                                        }
                                        else toast({
                                            status: 'error',
                                            title: 'Error.',
                                            description: 'Invalid File Type',
                                        })
                                    }}

                                    onClick={(e) => {
                                        e.target.value = null
                                    }}
                                />
                                <Center flexDir="column" gap="2.5">
                                    <Center flexDir="column" gap="2.5">
                                        <UploadIcon />
                                        {!codFile ? <Text textStyle="500" color="gray.600" textAlign="center">
                                            Click to upload in csv format (.csv) - Max (5 MB)
                                        </Text> : <Text textStyle="500" color="gray.600" textAlign="center">
                                            {codFile?.name}
                                        </Text>}
                                    </Center>
                                </Center>
                            </Center>
                        </FormControl>

                        <FormControl pt={6} isInvalid={!!errors.selectedCountry} isRequired onChange={(e) => {
                            setIsImportDisabled(true);
                            if (e?.target?.value) {
                                getCourierByCountry(e.target.value);
                            }
                        }} >
                            <FormLabel >Countries</FormLabel>
                            <Select value={selectedCountry} placeholder='Select option' onChange={(e) => {
                                getCourierByCountry(e.value);
                                setSelectedCountry(e);
                                setValue('selectedCountry', e)

                            }}
                                options={countries?.map((field) => ({ label: field.label, value: field.name }))}
                            >
                            </Select>
                            <FormErrorMessage>{errors.selectedCountry}</FormErrorMessage>
                        </FormControl>

                    </Box>
                    <Box>

                        <FormControl isRequired>
                            <FormLabel >Couriers</FormLabel>
                            <Box color={'blue'} fontSize={13} cursor={'pointer'} onClick={() => {
                                openFileInNewTab(pricingPlanSample)
                            }}>Download Pricing Plan Sample</Box>
                            <Box width={'15rem'} maxWidth={'15rem'} padding={2}>
                                {couriers.length === 0 && (<Text color={'red'} fontSize={12}>You have to select the country first</Text>)}
                                <div w={"100%"}>

                                    <Select
                                        isLoading={isCourierLoading}
                                        placeholder=""
                                        isMulti
                                        value={selectedCouriers}
                                        onChange={(e) => {
                                            setIsImportDisabled(true);
                                            setSelectedCouriers(e);
                                            const x = uploadedCouriers.filter(rec => e.some(d => d.value.name === rec.name));
                                            setUploadedCouriers(x)
                                        }}
                                        options={couriers?.map((field) => ({ label: field.label, value: field }))}
                                    />
                                </div>
                            </Box>
                        </FormControl>

                        <FormControl display={'flex'} flexDir={'column'} alignItems={'center'}>
                            {selectedCouriers?.map((courier, i) => {
                                return <>
                                    <Box key={i} display={'flex'} flexDir={'row'} alignItems={'center'} pt={8}>
                                        <FormLabel width={'6rem'}>{courier.label}</FormLabel>
                                        <Box display={'flex'} flexDir={'row'} >
                                            <Input
                                                mt={3}
                                                ref={inputRef2}
                                                type="file"
                                                aria-hidden="true"
                                                w={'7.3rem'}
                                                multiple={false}
                                                style={{ border: 'none' }}
                                                onChange={(e) => {
                                                    setIsImportDisabled(true);
                                                    if (e.target.files[0]) {
                                                        if (acceptedCourierFiles.includes(e.target.files[0]?.type) && e.target.files[0]?.size < 5000000) {
                                                            if (uploadedCouriers.find(record => record.id === courier.value.id)?.hasError)
                                                                uploadedCouriers.find(record => record.id === courier.value.id).hasError = false;
                                                            setUploadedCouriers([...uploadedCouriers, ...[{ ...courier.value, file: e.target.files[0] }]]);
                                                        } else toast({
                                                            status: 'error',
                                                            title: 'Error.',
                                                            description: 'Invalid File Type',
                                                        })
                                                    }
                                                }}

                                                onClick={(e) => {
                                                    e.target.value = null
                                                }}
                                            />
                                            <Box width={'15rem'} display={'flex'} flexDir={'row'} alignItems={'center'} gap={2}>
                                                {uploadedCouriers.find(record => record.id === courier.value.id)?.file?.name && !uploadedCouriers.find(record => record.id === courier.value.id)?.hasError && (
                                                    <Text paddingLeft={6} fontSize={14} color={'green'}>Uploaded</Text>
                                                )}
                                                {uploadedCouriers.find(record => record.id === courier.value.id)?.file?.name && (
                                                    <Button pt={1} size={'sm'} _hover={{ background: '#D4D4D4' }} background={'lightgray'} color={'red'} colorScheme='blue' mr={3}
                                                        onClick={() => {
                                                            setIsImportDisabled(true);
                                                            if (uploadedCouriers.find(record => record.id === courier.value.id)?.file) {
                                                                uploadedCouriers.find(record => record.id === courier?.value?.id).file['deleted'] = true;
                                                                const tempUploadedCouriers = uploadedCouriers.filter(cor => !cor?.file['deleted']);
                                                                if (tempUploadedCouriers)
                                                                    setUploadedCouriers(tempUploadedCouriers);
                                                            }
                                                        }}>
                                                        Delete
                                                    </Button>
                                                )}

                                                {/* {uploadedCouriers.find(record => record.id === courier.value.id)?.hasError && (
                                        <Button _hover={{ background: '#D4D4D4' }} background={'lightgray'} size={'sm'} onClick={() => {
                                            const file = new Blob(
                                                uploadedCouriers.find(record => record.id === courier.value.id).error,
                                                { type: 'text/csv' });
                                            const fileURL = URL.createObjectURL(file);
                                            window.open(fileURL);
                                        }} fontSize={14} fontWeight={'bold'} color={'red'}>Download error</Button>
                                    )} */}
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            })}
                        </FormControl>
                    </Box>

                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={isImportLoading || isValidateLoading} size={'sm'} _hover={{ background: '#D4D4D4' }} background={'white'} color={'#BB06CB'} colorScheme='blue' mr={3} onClick={onCloseModal}>
                        Cancel
                    </Button>
                    <Button size={'sm'} isDisabled={!isImportDisabled} isLoading={isValidateLoading} color={'white'} _hover={{ background: 'radial-gradient(47.01% 330.41% at 104.25% -161.43%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(60.53% 425.45% at -3.3% 255.71%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(20.19% 237.14% at 68.87% 261.43%, rgb(245, 132, 0) 0%, rgba(245, 132, 0, 0) 100%), rgb(163, 4, 179)' }}
                        background={'radial-gradient(47.01% 330.41% at 104.25% -161.43%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(60.53% 425.45% at -3.3% 255.71%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(20.19% 237.14% at 68.87% 261.43%, rgb(245, 132, 0) 0%, rgba(245, 132, 0, 0) 100%), rgb(187, 6, 203);'}
                        variant='ghost' onClick={handleSubmit(handelSubmit, onError)}

                    >Validate</Button>
                    <Button size={'sm'} isLoading={isImportLoading} isDisabled={isImportDisabled} ml={2} color={'white'} _hover={{ background: 'radial-gradient(47.01% 330.41% at 104.25% -161.43%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(60.53% 425.45% at -3.3% 255.71%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(20.19% 237.14% at 68.87% 261.43%, rgb(245, 132, 0) 0%, rgba(245, 132, 0, 0) 100%), rgb(163, 4, 179)' }}
                        background={'radial-gradient(47.01% 330.41% at 104.25% -161.43%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(60.53% 425.45% at -3.3% 255.71%, rgb(228, 49, 82) 0%, rgba(228, 49, 82, 0) 100%), radial-gradient(20.19% 237.14% at 68.87% 261.43%, rgb(245, 132, 0) 0%, rgba(245, 132, 0, 0) 100%), rgb(187, 6, 203);'}
                        variant='ghost' onClick={async () => {
                            setIsImportLoading(true);
                            let body = {
                                name: formValue.pricingPlanName,
                                description: formValue.description,
                                'pricing-plan-cod': codFile,
                                countries: `[${selectedCountry.value}]`
                            }
                            uploadedCouriers.forEach(rec => {
                                body[rec.name] = rec.file;
                            })

                            const eventSource = await addPricingPlan(
                                body,
                                toast                              
                            );

                            eventSource.stream();
                            setIsImportLoading(false);
                            fetchData(filterQuery);
                            onCloseModal();
                            toast({
                                status: "info",
                                title: "info.",
                                description: "Your request was successfully received. Please note it takes a few minutes to process.",
                            });

                            eventSource.addEventListener("message", ({ data }) => {
                                toast({
                                    status: 'success',
                                    title: 'Success.',
                                    description: 'Pricing Plan uploaded Successfully'
                                });
                            });
                        }}>Import</Button>

                </ModalFooter>
            </ModalContent>
        </Modal >
    </>
}