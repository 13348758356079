import { useState, useEffect } from "react";
import ListContainer from "components/Wrappers/ListContainer";
import { columns } from "variables/invoiceData";
import { getInvoices, getInvoicesExport } from "api/invoices";
import { useToast, Card } from "@chakra-ui/react";
import tableActions from "variables/tableActions";
import { ExportAction } from "components/actions/global/export";
import { Loader } from "components/Loader/loader";

function Invoices() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
  });

  const [openLoader, setOpenLoader] = useState(false);

  const [invoiceData, setInvoicesData] = useState([]);
  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getInvoices(query, toast);
    if (res) {
      setInvoicesData(res.invoices);
    }
    setIsLoading(false);
  };

  const globalActions = [
    {
      name: "export",
      component: () => <ExportAction onclickExport={onExport} />,
      validation: () => true,
    },
  ];

  const onExport = async () => {
    setOpenLoader(true);
    const exportQuery = { ...filterQuery };
    delete exportQuery?.page;
    delete exportQuery?.limit;
    const res = await getInvoicesExport(exportQuery, toast);
    openFileInNewTab(res);
    setOpenLoader(false);
  };

  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  useEffect(() => {
    fetchData(filterQuery);
  }, [filterQuery.page]);

  return (
    <>
      <Loader isLoading={openLoader} />

      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <ListContainer
          title="Invoices"
          columns={columns}
          data={invoiceData}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          hasPagination={false}
          isLoading={isLoading}
          tableActions={tableActions["Invoices"]}
          globalActions={globalActions}
        />
      </Card>
    </>
  );
}

export default Invoices;
