export const columns = [
  {
    type: "string",
    name: "name",
    label: "Name",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "brand",
    label: "Brand name",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "prefix",
    label: "Prefix",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "balance",
    label: "Wallet",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "phone",
    label: "Phone",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "string",
    name: "email",
    label: "Email",
    searchable: true,
    style: { width: "15rem" },
    show: true
  },
  {
    type: "link",
    name: "plan",
    label: "Assigned Plan",
    searchable: false,
    style: { width: "15rem" },
    show: true
  },
  {
    name: "actions",
    label: "",
    type: "actions",
    style: { width: "3rem" },
    searchable: false,
    stickyRight: true,
    show: true
  },
];
